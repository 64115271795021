import { createSlice } from "@reduxjs/toolkit";
import { set } from "lodash";

// SLICE REDUCER
const superAdminSlice = createSlice({
  name: "superAdminSlice",
  initialState: {
    isGridMode: false,
    statePendingApprovalPage: {
      rowsPerPage: 10,
      currentPage: 1,
    },
  },
  reducers: {
    setIsGridMode(state, action) {
      state.isGridMode = action.payload;
    },
    setPagination(state, action) {
      state.statePendingApprovalPage = action.payload;
    },
  },
});

export const { setPagination, setIsGridMode } = superAdminSlice.actions;
export default superAdminSlice.reducer;
