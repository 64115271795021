import { useQuery } from "@tanstack/react-query";
import { customAxios } from "utils/axios";

export const useGetAllCountry = ({ onError, onSuccess }) => {
  return useQuery({
    queryKey: ["getCountry"],
    queryFn: async () => {
      const res = await customAxios.get(`api/common/csc`);
      return res.data;
    },
    onError,
    onSuccess,
  });
};

export const useGetAllLanguage = ({ onError, onSuccess }) => {
  return useQuery({
    queryKey: ["getLanguage"],
    queryFn: async () => {
      const res = await customAxios.get(`api/common/lang`);
      return res.data;
    },
    onError,
    onSuccess,
  });
};

export const useGetAppQuery = () => {
  return useQuery({
    queryKey: ["apps"],
    queryFn: async () => {
      const res = await customAxios.get(`api/app`);
      return res.data;
    },
  });
};

export const useGetHolidays = (code) => {
  return useQuery({
    queryKey: ["holidays"],
    queryFn: async () => {
      const res = await customAxios.get(`api/common/holiday`);
      return res.data;
    },
  });
};
