import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";

import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { ModalConfirmationV2 } from "@bluesilodev/timhutcomponents";

const TableMenu = ({ onDetail, onEdit, onDelete }) => {
  const [isOpen, setIsOpen] = useState(false);
  const menuTriggerRef = useRef();
  const menuRef = useRef();
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const initialPositionRef = useRef(null);

  // Function to update menu position
  const updateMenuPosition = useCallback(() => {
    if (!menuTriggerRef.current || !isOpen) return;

    requestAnimationFrame(() => {
      const triggerRect = menuTriggerRef.current.getBoundingClientRect();
      const newPosition = {
        top: triggerRect.bottom + 6,
        left: triggerRect.right - 250,
      };

      // Store initial position when menu is first shown
      if (!initialPositionRef.current && isOpen) {
        initialPositionRef.current = newPosition.top;
      }

      // Check if position difference exceeds threshold
      if (
        initialPositionRef.current &&
        Math.abs(newPosition.top - initialPositionRef.current) > 30
      ) {
        setIsOpen(false);
        initialPositionRef.current = null;
        return;
      }

      setMenuPosition(newPosition);
    });
  }, [isOpen, setIsOpen]);

  const handleCloseMenuOnScroll = useCallback(() => {
    if (!isOpen) return;
    updateMenuPosition();
  }, [isOpen, updateMenuPosition]);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !menuTriggerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    },
    [setIsOpen]
  );

  // Handle menu position
  useEffect(() => {
    if (isOpen) {
      updateMenuPosition();
    }
  }, [isOpen, updateMenuPosition]);

  // Setup event listeners
  useEffect(() => {
    if (!isOpen) return;

    const captureOptions = { capture: true, passive: true };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener(
      "scroll",
      handleCloseMenuOnScroll,
      captureOptions
    );

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener(
        "scroll",
        handleCloseMenuOnScroll,
        captureOptions
      );
    };
  }, [isOpen, handleClickOutside, handleCloseMenuOnScroll]);

  const handleToggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative" ref={menuTriggerRef}>
      <EllipsisVerticalIcon
        className="h-5 w-5"
        aria-hidden="true"
        onClick={handleToggleMenu}
      />

      {isOpen &&
        menuPosition.top !== 0 &&
        menuPosition.left !== 0 &&
        ReactDOM.createPortal(
          <div
            ref={menuRef}
            className={`absolute py-5 bg-white w-[250px] h-max border-[2px] border-gray-300 shadow-md rounded-md flex justify-center items-center`}
            style={{
              top: `${menuPosition.top}px`,
              left: `${menuPosition.left}px`,
              position: "absolute",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex flex-col gap-3 w-full font-lato font-normal text-xs">
              {onEdit && (
                <div
                  onClick={onEdit}
                  className="px-5 hover:text-secondary hover:cursor-pointer duration-300 transition-shadow"
                >
                  Edit
                </div>
              )}

              {onDelete && (
                <div
                  onClick={onDelete}
                  className="px-5 hover:text-secondary hover:cursor-pointer duration-300 transition-shadow"
                >
                  Delete
                </div>
              )}

              {onDetail && (
                <div
                  onClick={onDetail}
                  className="px-5 hover:text-secondary hover:cursor-pointer duration-300 transition-shadow"
                >
                  Detail
                </div>
              )}
            </div>
          </div>,
          document.body
        )}
    </div>
  );
};

function TableMenuHandler({ show, onEditClick, onDeleteClick }) {
  const [showModal, setShowModal] = React.useState(false);
  const confirmModal = () => {
    setShowModal(false);
    onDeleteClick();
  };
  const cancelModal = () => {
    setShowModal(false);
  };
  return (
    <React.Fragment>
      <TableMenu onEdit={onEditClick} onDelete={() => setShowModal(true)} />
      {showModal && (
        // <ModalConfirmation
        //   headMessage="Are you sure want to delete it?"
        //   onClose={cancelModal}
        //   onConfirm={confirmModal}
        // />
        <ModalConfirmationV2
          className={"min-w-[600px]"}
          title={"Delete Leave"}
          headMessage={""}
          message={
            <div className="w-full text-lg font-light text-left">
              Do you want to delete this item?
            </div>
          }
          // onClose={() => setIsShow(false)}
          onClose={cancelModal}
          // onConfirm={() => {
          //   requestPayroll({ id: original?._id });
          //   dispatch(PayrollApi.util.invalidateTags(["payroll"]));
          //   setIsShow(false);
          // }}
          onConfirm={confirmModal}
        />
      )}
    </React.Fragment>
  );
}

export { TableMenu, TableMenuHandler };
