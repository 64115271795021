import React, { useLayoutEffect, useRef, useState } from "react";
import { SearchBox, Button, DataTable } from "@bluesilodev/timhutcomponents";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";

import { useGetAllEmployeeStatus } from "service/employeeStatusAPI";
import { adminRole } from "utils/roleFromLocalStorage";

import TableMenu from "./TableMenu";
import { Fi2User } from "components/icons";

function EmployeeStatus() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [keyword, setKeyword] = useState("");
  const [reqDefaultData, setReqDefaultData] = useState(false);

  const inputRef = useRef(null);
  const isPromptShown = useRef(false);

  const handleDirectPage = () => {
    navigate("/createEmployeeStatus");
  };

  // COLUMN
  let columnData = [
    {
      id: "employee_type",
      accessorFn: (row) => row.employeeType,
      header: () => <div>Employee Type</div>,
      enableSorting: true,
      sortFunction: undefined,
    },
    {
      id: "type_description",
      accessorFn: (row) => row.description,
      header: () => <div>Type Description</div>,
      enableSorting: true,
      sortFunction: undefined,
    },
    {
      id: "status",
      accessorFn: (row) => row.isActive,
      cell: (param) => {
        const val = param.getValue();
        return <div>{val === true ? "Active" : "Non Active"}</div>;
      },
      header: () => <div>Status</div>,
      enableSorting: true,
      sortFunction: undefined,
    },
  ];

  if (adminRole) {
    columnData.push({
      id: "action",
      disableSorting: true,
      header: () => "",
      accessorFn: (row) => {
        return row;
      },
      cell: (param) => {
        const data = param.getValue();

        return (
          <div className="cursor-pointer w-fit">
            <TableMenu data={data} />
          </div>
        );
      },
    });
  }

  const { data, isSuccess } = useGetAllEmployeeStatus(keyword, reqDefaultData, {
    onSuccess: () => {
      queryClient.invalidateQueries("getEmployeeStatus");
    },
  });

  // FETCH DATA IF SUCCES GET API
  // if (isSuccess && data?.data.length !== 0) {
  //   refetch();
  // }

  // CHANGE FOR FOCUS INPUT
  useLayoutEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    

  }, [keyword, reqDefaultData, isSuccess, data]);

  // if (isSuccess && data?.data.length === 0 && !loadingEmployeeStatus) {
  //   const confirmDefault = window.confirm("Do you want to add default data?");

  //   console.log("CONFOIR: ", confirmDefault);
  //   if (confirmDefault) {
  //     setReqDefaultData(true);
  //   }
  //   isPromptShown.current = true;
  // }

  // console.log("STTUS : ", {
  //   status: isSuccess && data?.data.length === 0,
  // });

  return (
    <div className="w-full h-full pt-10 overflow-y-auto">
      <div className="flex justify-between w-full">
        <SearchBox
          className={"w-[300px]"}
          value={keyword}
          ref={inputRef}
          onChange={(e) => setKeyword(e.target.value)}
          placeholder="Search by employee type"
        />
        {adminRole && (
          <Button
            style={"solid"}
            className={"w-[240px]"}
            onClick={handleDirectPage}
            label={
              <div className="flex gap-2">
                <Fi2User color={"white"} />
                <div>Create Employee Status</div>
              </div>
            }
          />
        )}
      </div>

      <div className="py-8">
        <DataTable
          columns={columnData}
          currentPage={1}
          data={!data?.data ? <h1>Data Not Found!</h1> : data?.data}
          onChangePage={() => {}}
          onChangeRowsPerPage={() => {}}
          pagination
          rowsPerPage={10}
          title="Example DataTable"
          totalRows={100}
          className="max-h-[calc(100dvh-360px)]"
        />
      </div>
    </div>
  );
}

export default EmployeeStatus;
