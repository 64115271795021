import { FiGrid, FiList } from "components/icons";
import { memo } from "react";
import { cn } from "utils/common";

const ViewSwitch = ({ isGridMode, setIsGridMode }) => {
  return (
    <div className="w-[150px] border-[1px] border-black flex gap-1 rounded-md p-1">
      <button
        type="button"
        className="w-1/2 group"
        onClick={() => setIsGridMode(true)}
      >
        <div
          className={cn(
            "h-[50px] w-full rounded-md flex justify-center items-center transition-colors",
            isGridMode
              ? "bg-orange-500 text-white"
              : "bg-white hover:bg-orange-100"
          )}
        >
          <FiGrid className="w-5 h-5" color={isGridMode ? "white" : "black"} />
        </div>
      </button>

      <button
        type="button"
        className="w-1/2 group"
        onClick={() => setIsGridMode(false)}
      >
        <div
          className={cn(
            "h-[50px] w-full rounded-md flex justify-center items-center transition-colors",
            !isGridMode
              ? "bg-orange-500 text-white"
              : "bg-white hover:bg-orange-100"
          )}
        >
          <FiList className="w-5 h-5" color={!isGridMode ? "white" : "black"} />
        </div>
      </button>
    </div>
  );
};

export default memo(ViewSwitch);
