import React, { useState, useEffect } from "react";
import { Accordion, Button, DataTable } from "@bluesilodev/timhutcomponents";
import { useFormikContext } from "formik";
import { useGetLeaveGlobal } from "service/leaveApi";
import { useQueryClient } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";

import EmployeeMentDetailShare from "pages/shared/formUser/EmployeementDetailShare";
import ActionLeave from "pages/shared/actionLeave";
import { setLeaveData } from "store/employeeSlice";
import { LeaveTypeAddModalForm } from "pages/shared/formLeaveType";
import { FiPlusSvg } from "components/icons";

function SuperAdminEmployeeMentDetails() {
  const dispatch = useDispatch();

  const { values, handleBlur, handleChange, errors, touched, setFieldValue } =
    useFormikContext();
  const queryClient = useQueryClient();
  const { leaveData } = useSelector((state) => state.employeeSlice);
  const [addLeaveTypeModal, addLeaveTypeOpen] = useState(false);

  // GET DATA GLOBAL LEAVE ADMIN
  const {
    data: globalData,
    isPending: pendingGlobal,
    isSuccess,
  } = useGetLeaveGlobal({
    onSuccess: () => {
      queryClient.invalidateQueries("globalLeave");
    },
    onError: (err) => {
      console.log("ERR: ", err);
    },
  });

  useEffect(() => {
    if (isSuccess && globalData) {
      dispatch(setLeaveData(globalData.data));
    }
  }, [globalData, isSuccess]);

  if (pendingGlobal) {
    return <div>Loading...</div>;
  }

  // COLUMN
  const dataColumn = [
    {
      id: "leaveType",
      accessorFn: (row) => {
        return row.name;
      },
      header: () => <span>Leave Type</span>,
      enableSorting: true,
    },
    {
      id: "amount",
      accessorFn: (row) => row.defaultAmount,
      header: () => <span>Default Amount</span>,
      enableSorting: true,
    },
    {
      id: "gainPermont",
      accessorFn: (row) => row.gainPerMonth,
      header: () => <span>Gain Per Month</span>,
      enableSorting: true,
    },
    {
      id: "expired",
      accessorFn: (row) => row.expiredCarryOver,
      header: () => <span>Expired Carry Over</span>,
      enableSorting: true,
    },
    {
      id: "maxCarry",
      accessorFn: (row) => row?.maxCarryOver,
      header: () => <span>Max Carry Over</span>,
      enableSorting: true,
    },

    {
      id: "action",
      disableSorting: true,
      header: () => "",
      accessorFn: (row) => {
        return row && row;
      },
      cell: (param) => {
        if (param) {
          const data = param.getValue();

          return (
            <>
              <ActionLeave data={data} />
            </>
          );
        }
      },
    },
  ];

  return (
    <div className="w-full ">
      <EmployeeMentDetailShare
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        touched={touched}
        values={values}
        setFieldValue={setFieldValue}
      />

      <div className="w-full">
        <Accordion
          title={"Leave Settings"}
          icons={[
            {
              icon: (
                <div className="p-2 ">
                  <Button
                    type={"button"}
                    className={"w-[200px] "}
                    style={"solid"}
                    label={
                      <div className="flex items-center justify-center w-full gap-2 p-2 text-white">
                        <FiPlusSvg
                          className={"text-white w-[20px] h-[20px] "}
                        />
                        <h1>Add New Leave Type</h1>
                      </div>
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      addLeaveTypeOpen(true);
                    }}
                  />
                </div>
              ),
            },
          ]}
          children={
            <div className="py-5">
              <div className="pt-5">
                <DataTable
                  columns={dataColumn}
                  data={leaveData}
                  pagination={true}
                  title="Employee Data"
                />
              </div>
            </div>
          }
        />
        <LeaveTypeAddModalForm
          addLeaveTypeModal={addLeaveTypeModal}
          addLeaveTypeOpen={addLeaveTypeOpen}
        />
      </div>
    </div>
  );
}

export default SuperAdminEmployeeMentDetails;
