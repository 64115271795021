import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

import { useEditUser, useGetUserById } from "service/userAPI";
import {
  usePostUserInform,
  useUpdateUserInform,
} from "service/userInformationAPI";

import { formatCalendarDate } from "configures/formatDate";
import EditFormShared from "pages/shared/formUser/EditFormShared";
import { getValidationSchemaMyAccount } from "pages/userSettings/yupSchema/myAccountSchema";

import { setAccountStudy } from "store/employeeSlice";
import { alertError, alertSuccess } from "utils/alert";
import {
  adminAndSuperVisorRole,
  adminRole,
  superAdminRole,
  userLoginNow,
} from "utils/roleFromLocalStorage";
import EmployeeMentDetails from "./EmployeeMentDetails";
import IdentificationDoc from "./IdentificationDoc";
import PaymentDetails from "./PaymentDetails";
import PersonalInfo from "./PersonalInfo";

dayjs.extend(customParseFormat);

function MyAccount() {
  const { currentRole } = useSelector((state) => state.loginSlice);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const { accountStudy } = useSelector((state) => state.employeeSlice);
  const [currentStep, setCurrentStep] = useState(1);

  const initialValues = {
    // DATA USER
    organizationID: "",
    userInformationID: "",
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    countryPhoneNumber: "65",
    phoneNumber: "",
    photo: "",
    gender: "",
    bloodTypes: "",
    religions: "",
    dateOfBirth: "",
    maritalStatus: "",
    address: "",
    country: "",
    postalCode: "",
    role: [],
    reportTo: "",

    employeementDetail: {
      employeeStatus: "",
      locations: "",
      remoteLocation: "",
      // employeeID: "",
      joinDate: "",
      endDate: "",
      departments: "",
      leaveDetail: {
        leave: false,
        leaveSettings: [],
      },
      jobLevel: "",
      jobPosition: "",
      isPermanentEmployee: false,
    },

    hobbies: [],
    skills: [],
    languages: [],

    identificationDocument: {
      identificationType: "",
      identityExpiredDate: "",
      nationality: "",
      identityNumber: "",
      isPermanentDate: false,
      covid19VaksinationStatus: "",
      attachments: [],
    },
    paymentDetail: {
      rateOfPays: "",
      banks: "",
      basicSalary: "",
      currencies: "",
      numberAccount: "",
      allowOvertime: false,
      paymentMethods: "",
      accountName: "",
      groupContribution: "",
      cpfAccountNumber: "",
      sdlApplicable: false,
      fwlApplicable: false,
      additionalComponents: [],
      monthlyAllowances: [
        // {
        //   typeOfAllowance: "",
        //   amount: "",
        // },
      ],
    },
  };

  const uId = userLoginNow?.uId;
  const userInformId = userLoginNow?.userInformationID;

  // GET ONE DATA API USER
  const {
    data: userData,
    isPending: loadingUser,
    isSuccess,
  } = useGetUserById(uId, {
    onSuccess: () => {
      queryClient.invalidateQueries("getUserById");
    },
  });

  const handlePlus = (values, setTouched, setFieldTouched, errors) => {
    const {
      firstName,
      lastName,
      userName,
      email,
      password,
      confirmPassword,
      // countryPhoneNumber,
      phoneNumber,
      photo,
      gender,
      bloodTypes,
      religions,
      dateOfBirth,
      maritalStatus,
      address,
      country,
      postalCode,
      hobbies,
      skills,
      languages,
      role,
      employeementDetail,
      identificationDocument,
    } = values;

    if (currentStep === 1) {
      if (
        firstName.trim() === "" ||
        // lastName.trim() === "" ||
        userName.trim() === "" ||
        email.trim() === "" ||
        // password.trim() === "" ||
        // confirmPassword.trim() === "" ||
        // countryPhoneNumber === "" ||
        phoneNumber === "" ||
        phoneNumber === null ||
        gender.trim() === "" ||
        bloodTypes.trim() === "" ||
        religions.trim() === "" ||
        maritalStatus.trim() === "" ||
        // country.trim() === "" ||
        // postalCode === "" ||
        // postalCode === null ||
        dateOfBirth.trim() === "" ||
        dateOfBirth === "dd/mm/yy"
        // address.trim() === "" ||
        // photo.length === 0 ||
        // hobbies.length === 0 ||
        // skills.length === 0 ||
        // languages.length === 0
      ) {
        setTouched({
          firstName: true,
          // lastName: true,
          userName: true,
          email: true,
          // password: true,
          // confirmPassword: true,
          // countryPhoneNumber: true,
          phoneNumber: true,
          gender: true,
          bloodTypes: true,
          religions: true,
          maritalStatus: true,
          country: true,
          postalCode: true,
          hobbies: true,
          skills: true,
          languages: true,
          photo: true,
          address: true,
          dateOfBirth: true,
        });
        return;
      } else {
        if (password !== confirmPassword) {
          setTouched({
            password: true,
            confirmPassword: true,
          });
          return;
        }
        setCurrentStep(currentStep + 1);
        return;
      }
    } else if (currentStep === 2) {
      if (
        (adminRole || superAdminRole) &&
        (role.length === 0 ||
          employeementDetail.departments.trim() === "" ||
          employeementDetail.employeeStatus.trim() === "" ||
          employeementDetail.endDate.trim() === "" ||
          employeementDetail.jobPosition.trim() === "" ||
          employeementDetail.joinDate.trim() === "" ||
          employeementDetail.locations.trim() === "" ||
          (errors &&
            errors.employeementDetail &&
            errors.employeementDetail.joinDate) ||
          (errors &&
            errors.employeementDetail &&
            errors.employeementDetail.endDate))
      ) {
        setFieldTouched("role", true);
        setFieldTouched("employeementDetail.departments", true);
        setFieldTouched("employeementDetail.employeeStatus", true);
        setFieldTouched("employeementDetail.endDate", true);
        setFieldTouched("employeementDetail.jobPosition", true);
        setFieldTouched("employeementDetail.joinDate", true);
        setFieldTouched("employeementDetail.locations", true);
        return;
      } else {
        setCurrentStep(currentStep + 1);
        return;
      }
    } else if (currentStep === 3) {
      if (
        (adminRole || superAdminRole) &&
        (identificationDocument.identificationType.trim() === "" ||
          identificationDocument.identityNumber === "" ||
          identificationDocument.identityNumber === null ||
          identificationDocument.nationality.trim() === "" ||
          // identificationDocument.covid19VaksinationStatus.trim() === "" ||
          // identificationDocument.identityExpiredDate === "dd/mm/yy" ||
          // identificationDocument.attachments.length === 0 ||
          identificationDocument.identityExpiredDate.trim() === "")
      ) {
        setFieldTouched("identificationDocument.identificationType", true);
        setFieldTouched("identificationDocument.identityNumber", true);
        setFieldTouched("identificationDocument.nationality", true);
        setFieldTouched(
          "identificationDocument.covid19VaksinationStatus",
          true
        );
        setFieldTouched("identificationDocument.identityExpiredDate", true);
        setFieldTouched("identificationDocument.attachments", true);
        return;
      } else {
        setCurrentStep(currentStep + 1);
        return;
      }
    }
  };

  // UPDATE DATA API USER
  const { mutateAsync: updateUser, isPending: pendingUpdateUser } = useEditUser(
    uId,
    {
      onSuccess: () => {
        alertSuccess("Employee updated successfully!");
        queryClient.invalidateQueries("getUserById");
      },
      onError: () => {
        alertError("UPDATE User is Failed!");
      },
    }
  );

  // POST DATA USER INFORMATION
  const { mutateAsync: postUserInform, isPending: pendingPorstUserInform } =
    usePostUserInform({
      onSuccess: () => {
        alertSuccess("POST DATA USERINFORMATION SUCCESS!");
        queryClient.invalidateQueries("getOneUserInform");
      },
      onError: () => {
        alertError("Failed Create User Information!");
      },
    });

  // UPDATE DATA USERINFORMATION
  const { mutateAsync: updateUserInform, isPending: pendingUpdaterUserInform } =
    useUpdateUserInform(userInformId, {
      onSuccess: () => {
        alertSuccess("SUCCESS UPDATE USERINFORMATION !");
        queryClient.invalidateQueries("getUserById");
        queryClient.invalidateQueries("userInform");
      },
      onError: () => {
        alertError("FAILED UPDATE USERINFORMATION !");
      },
    });

  const apiDataUser = userData?.data[0] ? userData?.data[0] : userData?.data;

  const canEditPassword = currentRole === "Admin" || apiDataUser?.uId === uId;

  // console.log("can edit", canEdit);

  // ADDITIONAL COMPONENTS ITEM
  const additionalComponents =
    apiDataUser?.userInformation?.paymentDetail?.additionalComponents ||
    [
      // {
      //   typeOfAllowance: "",
      //   amount: "",
      // },
    ];

  // MONTHLY ALLOWANCES ITEM
  const monthlyAllowances =
    apiDataUser?.userInformation?.paymentDetail?.monthlyAllowances ||
    [
      // {
      //   typeOfAllowance: "",
      //   amount: "",
      // },
    ];

  useEffect(() => {
    if (isSuccess && userData) {
      const educationData = userData.data[0]
        ? userData.data[0]?.userInformation?.education
        : [];

      dispatch(setAccountStudy(educationData));
    }
  }, [isSuccess, userData]);

  if (
    loadingUser ||
    pendingUpdateUser ||
    pendingPorstUserInform ||
    pendingUpdaterUserInform
  ) {
    return <h1>Loading...</h1>;
  }

  const setToDataApi = {
    ...initialValues,
    employeeID: apiDataUser?.employeeID || "",
    userInformationID: apiDataUser?.userInformationID || "",
    organizationID: apiDataUser?.organizationID || "",
    firstName: apiDataUser?.firstName || "",
    lastName: apiDataUser?.lastName || "",
    userName: apiDataUser?.userName || "",
    email: apiDataUser?.email || "",
    password: "",
    confirmPassword: "",
    countryPhoneNumber: apiDataUser?.countryPhoneNumber || "",
    phoneNumber: apiDataUser?.phoneNumber || "",
    photo: apiDataUser?.photo[0]?.link || [],
    gender: apiDataUser?.gender || "",
    bloodTypes: apiDataUser?.bloodTypes || "",
    religions: apiDataUser?.religions || "",
    dateOfBirth: formatCalendarDate(apiDataUser?.dateOfBirth),
    maritalStatus: apiDataUser?.maritalStatus || "",
    address: apiDataUser?.address || "",
    country: apiDataUser?.country || "",
    postalCode: apiDataUser?.postalCode || "",
    hobbies: apiDataUser?.userInformation?.hobbies || [],
    skills: apiDataUser?.userInformation?.skills || [],
    languages: apiDataUser?.userInformation?.languages || [],
    role: apiDataUser?.role || "",
    reportTo: apiDataUser?.reportTo || "",
    employeementDetail: {
      employeeStatus:
        apiDataUser?.userInformation?.employeementDetail?.employeeStatus || "",

      // employeeID: "",
      joinDate:
        formatCalendarDate(
          apiDataUser?.userInformation?.employeementDetail?.joinDate
        ) || "",
      endDate:
        formatCalendarDate(
          apiDataUser?.userInformation?.employeementDetail?.endDate
        ) || "",
      departments:
        apiDataUser?.userInformation?.employeementDetail?.departments || "",

      jobPosition:
        apiDataUser?.userInformation?.employeementDetail?.jobPosition || "",

      remoteLocation:
        apiDataUser?.userInformation?.employeementDetail?.remoteLocation || "",

      locations: apiDataUser?.locations?.uId || "",

      isPermanentEmployee:
        apiDataUser?.userInformation?.employeementDetail?.isPermanentEmployee ||
        false,
    },
    identificationDocument: {
      identificationType:
        apiDataUser?.userInformation?.identificationDocument
          ?.identificationType || "",
      identityExpiredDate:
        formatCalendarDate(
          apiDataUser?.userInformation?.identificationDocument
            ?.identityExpiredDate
        ) || "",
      nationality:
        apiDataUser?.userInformation?.identificationDocument?.nationality || "",
      identityNumber:
        apiDataUser?.userInformation?.identificationDocument?.identityNumber ||
        "",
      isPermanentDate:
        apiDataUser?.userInformation?.identificationDocument?.isPermanentDate ||
        false,
      covid19VaksinationStatus:
        apiDataUser?.userInformation?.identificationDocument
          ?.covid19VaksinationStatus || "",
      attachments:
        apiDataUser?.userInformation?.identificationDocument?.attachments?.[0]
          ?.link || [],
    },
    paymentDetail: {
      rateOfPays: apiDataUser?.userInformation?.paymentDetail?.rateOfPays || "",
      banks: apiDataUser?.userInformation?.paymentDetail?.banks || "",
      basicSalary:
        apiDataUser?.userInformation?.paymentDetail?.basicSalary || "0",
      currencies: apiDataUser?.userInformation?.paymentDetail?.currencies || "",
      numberAccount:
        apiDataUser?.userInformation?.paymentDetail?.numberAccount || "",
      allowOvertime:
        apiDataUser?.userInformation?.paymentDetail?.allowOvertime || false,
      paymentMethods:
        apiDataUser?.userInformation?.paymentDetail?.paymentMethods || "",
      accountName:
        apiDataUser?.userInformation?.paymentDetail?.accountName || "",
      groupContribution:
        apiDataUser?.userInformation?.paymentDetail?.groupContribution || "",
      cpfAccountNumber:
        apiDataUser?.userInformation?.paymentDetail?.cpfAccountNumber || "",
      sdlApplicable:
        apiDataUser?.userInformation?.paymentDetail?.sdlApplicable || false,
      fwlApplicable:
        apiDataUser?.userInformation?.paymentDetail?.fwlApplicable || false,
      additionalComponents:
        // SHOW THE ARRAY IF LENGTH > 0 || USING ARRAY FILL STRING : ""
        additionalComponents.length > 0
          ? additionalComponents
          : [
              // {
              //   typeOfAllowance: "",
              //   amount: "",
              // },
            ],
      monthlyAllowances:
        // SHOW THE ARRAY IF LENGTH > 0 || USING ARRAY FILL STRING : ""
        monthlyAllowances.length > 0
          ? monthlyAllowances
          : [
              // {
              //   typeOfAllowance: "",
              //   amount: "",
              // },
            ],
    },
  };

  const handleSubmitForm = async (values) => {
    const formUser = new FormData();
    const formUserInformation = new FormData();

    // GENERATE TO CALENDAR
    const customDateFormat = "DD/MM/YYYY";
    const joinDate = dayjs(
      values.employeementDetail.joinDate,
      customDateFormat
    );
    const endDate = dayjs(values.employeementDetail.endDate, customDateFormat);
    const dateOfBirth = dayjs(values.dateOfBirth, customDateFormat);
    const identityExpiredDate = dayjs(
      values.identificationDocument.identityExpiredDate,
      customDateFormat
    );

    const employeementDetail = JSON.stringify({
      ...values.employeementDetail,
      joinDate: joinDate,
      endDate: endDate,
    });

    const identification = JSON.stringify({
      ...values.identificationDocument,
      identityExpiredDate: identityExpiredDate,
    });

    const payment = JSON.stringify(values.paymentDetail);
    const hobby = JSON.stringify(values.hobbies);
    const skill = JSON.stringify(values.skills);
    const language = JSON.stringify(values.languages);
    const educationString = JSON.stringify(accountStudy);

    formUserInformation.append("hobbies", hobby);
    formUserInformation.append("skills", skill);
    formUserInformation.append("languages", language);
    formUserInformation.append("education", educationString);

    formUserInformation.append("employeementDetail", employeementDetail);
    formUserInformation.append("identificationDocument", identification);
    formUserInformation.append("paymentDetail", payment);

    formUserInformation.append(
      "attachments",
      values.identificationDocument.attachments
    );

    formUser.append("organizationID", values.organizationID);
    formUser.append("firstName", values.firstName);
    formUser.append("lastName", values.lastName);
    formUser.append("userName", values.userName);
    formUser.append("email", values.email);
    formUser.append("password", values.password);
    formUser.append("countryPhoneNumber", values.countryPhoneNumber);
    formUser.append("phoneNumber", values.phoneNumber);
    formUser.append("photo", values.photo);
    formUser.append("gender", values.gender);
    formUser.append("bloodTypes", values.bloodTypes);
    formUser.append("religions", values.religions);
    formUser.append("dateOfBirth", dateOfBirth);
    formUser.append("maritalStatus", values.maritalStatus);
    formUser.append("address", values.address);
    formUser.append("country", values.country);
    formUser.append("postalCode", values.postalCode);
    formUser.append("role", JSON.stringify(values.role));
    formUser.append("reportTo", values.reportTo);

    // CHECK IF !USERINFORMAION ID ? POST DATA : UPDATE DATA

    try {
      let resp, respUpdate, uId;

      if (
        !values.userInformationID &&
        (superAdminRole || adminAndSuperVisorRole)
      ) {
        // POST DATA USERFORMATION
        resp = await postUserInform(formUserInformation);

        if (resp) {
          // GET DATA FROM RESP POST USER INFORMATION
          uId = resp.data.uId;

          // WAIT FOR USERINFORMATION ID AND THE UPDATE
          formUser.append("userInformationID", uId);
        }
      } else {
        // UPDATE USER INFORMATION
        // if (superAdminRole || adminAndSuperVisorRole) {
        //   resp = await updateUserInform(formUserInformation);

        //   if (resp) {
        //     uId = resp.data.uId;
        //     // UPDATE DATA USER
        //     formUser.append("userInformationID", uId);
        //   }
        // }
        resp = await updateUserInform(formUserInformation);

        if (resp) {
          uId = resp.data.uId;
          // UPDATE DATA USER
          formUser.append("userInformationID", uId);
        }
      }

      // UPDATE USER
      respUpdate = await updateUser(formUser);

      localStorage.setItem("accessToken", respUpdate.accessToken);

      window.location.reload();

      // UPDATE LOCAL STORAGE
      // const data = { ...respUpdate.data };

      // UPDATE userInformationID in user object
      // const updatedUser = { ...data, userInformationID: uId };

      // const updateLocalStorage = {
      //   ...payload,
      //   payLoad: {
      //     ...updatedUser, // Update user object
      //   },
      // };

      // localStorage.setItem("payload", JSON.stringify(updateLocalStorage));
    } catch (error) {
      console.log("ERR: ", error);
    }
  };

  return (
    <EditFormShared
      initialValues={setToDataApi}
      currentStep={currentStep}
      handlePlus={handlePlus}
      handleSubmitForm={handleSubmitForm}
      setCurrentStep={setCurrentStep}
      validationSchema={getValidationSchemaMyAccount({
        isAdminOrSuperAdmin: adminRole || superAdminRole,
      })}
    >
      <div className={`${currentStep !== 1 && "hidden"} `}>
        <PersonalInfo canEditPassword={canEditPassword} />
      </div>
      <div className={`${currentStep !== 2 && "hidden"}`}>
        <EmployeeMentDetails />
      </div>
      <div className={`${currentStep !== 3 && "hidden"}`}>
        <IdentificationDoc />
      </div>
      <div className={`${currentStep !== 4 && "hidden"}`}>
        <PaymentDetails />
      </div>
    </EditFormShared>
  );
}

export default MyAccount;
