import {
  InputSelect,
  InputText,
  ModalConfirmationV3,
  TextArea,
} from "@bluesilodev/timhutcomponents";
import countryCodes from "@bluesilodev/timhutcomponents/src/stories/forms/CountrySelect/CountryCodes";
import { useState } from "react";
import { FiCheckCircle, FiChevronDown, FiXCircle } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import {
  useSuperAdminGetPendingApprovalById,
  useSuperAdminUpdatePendingApproval,
} from "service/userAPI";
import { alertError, alertSuccess } from "utils/alert";
import { HiOutlineXCircle } from "react-icons/hi";
import { useGetAllCompany } from "service/companyAPI";
import dayjs from "dayjs";

export default function DetailPendingApproval() {
  const { uId } = useParams();
  const [openApproveModal, setOpenApproveModal] = useState(false);
  const [openRejectModal, setOpenRejectModal] = useState(false);
  const [selectedCompany, setSelectedCompany] = useState(undefined);
  const [reasonText, setReasonText] = useState("");
  const [reasonError, setReasonError] = useState("");
  const { data: pendingApproval, isPending } =
    useSuperAdminGetPendingApprovalById(uId);
  const { data: companies, isPending: isPendingCompanies } = useGetAllCompany({
    rowsPerPage: 99999,
  });
  const { mutateAsync: updatePendingApproval, isPending: isPendingUpdate } =
    useSuperAdminUpdatePendingApproval(uId);
  const navigate = useNavigate();

  const handleReject = async () => {
    if (!reasonText) {
      setReasonError("Please enter a reason for refusing!");
      return;
    }

    try {
      const res = await updatePendingApproval({
        status: "Reject",
        reason: reasonText,
      });

      if (res.status === 200) {
        alertSuccess("Successfully rejected!");
        navigate(`/super/pendingApproval`);
      }
    } catch (error) {
      alertError(error.message);
    }
  };

  const handleApprove = async () => {
    if (!selectedCompany) {
      alertError("Please select a company first!");
      return;
    }

    try {
      const res = await updatePendingApproval({
        status: "Approve",
        organizationID: selectedCompany,
      });

      if (res.status === 200) {
        alertSuccess("Successfully approved!");
        navigate(`/super/pendingApproval`);
      }
    } catch (error) {
      alertError(error.message);
    }
  };

  if (isPending || isPendingCompanies) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col gap-8">
      <div className="flex items-center justify-between w-full">
        <p className="text-sm font-bold">
          Joining on{" "}
          {dayjs(pendingApproval.data.createdAt).format("DD MMMM YYYY, HH:mm")}
        </p>
        <div className="flex justify-end flex-1 gap-6 text-sm font-bold text-white">
          <button
            className="bg-[#DD4848] hover:bg-opacity-80 p-5 w-full max-w-64 flex items-center justify-center gap-2 rounded-lg"
            onClick={() => setOpenRejectModal(true)}
          >
            <FiXCircle />
            <span>Reject</span>
          </button>
          <button
            className="bg-[#4BD394] hover:bg-opacity-80 p-5 w-full max-w-64 flex items-center justify-center gap-2 rounded-lg"
            onClick={() => setOpenApproveModal(true)}
          >
            <FiCheckCircle />
            <span>Approve</span>
          </button>
        </div>
      </div>
      <div className="p-4 rounded-2xl bg-white shadow-md border border-[#E3E3E3] flex flex-col gap-4">
        <div className="w-full pb-4 border-b-2 border-[#F1F1F1] flex justify-between">
          <p className="text-lg font-latoBold">Employee Details</p>
          <FiChevronDown fontSize={24} />
        </div>
        <div className="flex gap-6">
          <img
            src={pendingApproval.data.photo[0].link}
            alt="Profile Pending Approval User"
            className="flex items-center justify-center w-full text-xs text-white rounded-full aspect-square max-w-36 max-h-36 bg-zinc-400"
          />

          <div className="flex w-full gap-6 flex-wrap [&>*]:w-[calc(100%/3-1.5rem)]">
            <InputText
              title="First Name"
              value={pendingApproval.data.firstName}
              disabled
            />
            <InputText
              title="Last Name"
              value={pendingApproval.data.lastName}
              disabled
            />
            <InputText title="Employee ID" value="-" disabled />
            <InputSelect
              title="Location"
              // placeholder={countryCodes[pendingApproval.data.country].name}
              placeholder="-"
              disabled
            />
            <InputSelect title="Department" placeholder="-" disabled />
            <InputSelect title="Job Position" placeholder="-" disabled />
            <InputText
              title="Email"
              type="email"
              value={pendingApproval.data.email}
              disabled
            />
            <InputText
              title="Contact Number"
              type="tel"
              value={`${pendingApproval.data.countryPhoneNumber}${pendingApproval.data.phoneNumber}`}
              disabled
            />
            <InputSelect title="Status" placeholder="Pending" disabled />
          </div>
        </div>
      </div>

      {openApproveModal && (
        <div className="[&>div>div]:last:!min-w-[600px] [&>div>div]:last:!max-w-[600px]">
          <ModalConfirmationV3
            title="Which Company to Assign"
            variant="success"
            content={
              <InputSelect
                title="Company"
                placeholder="Select Company"
                options={companies.data.map((company) => ({
                  label: company.companyName,
                  value: company.uId,
                }))}
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
              />
            }
            confirmLabel="Approve"
            onCancel={() => {
              setOpenApproveModal(false);
              setSelectedCompany(undefined);
            }}
            onConfirm={handleApprove}
            size="lg"
            confirmIcon={<FiCheckCircle strokeWidth={3} />}
            confirmButtonClassname="disabled"
            cancelButtonClassname="border-orange-600 text-orange-600 hover:bg-orange-50"
            isLoading={isPendingUpdate}
          />
        </div>
      )}

      {openRejectModal && (
        <div className="[&>div>div]:last:!min-w-[600px] [&>div>div]:last:!max-w-[600px]">
          <ModalConfirmationV3
            title="Reason for Refusing"
            variant="danger"
            content={
              <TextArea
                rows={4}
                label="Reason"
                value={reasonText}
                onChange={(e) => {
                  if (e.target.value.length > 0 && reasonError) {
                    setReasonError("");
                  }
                  setReasonText(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                  }
                }}
                error={reasonError}
                placeholder="Please enter a reason for refusing"
                required
              />
            }
            confirmLabel="Reject"
            onCancel={() => {
              setOpenRejectModal(false);
              setReasonText("");
              setReasonError("");
            }}
            onConfirm={handleReject}
            size="lg"
            confirmIcon={<HiOutlineXCircle />}
            cancelButtonClassname="border-orange-600 text-orange-600 hover:bg-orange-50"
            isLoading={isPendingUpdate}
          />
        </div>
      )}
    </div>
  );
}
