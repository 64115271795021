import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSuperAdminGetPendingApproval } from "service/userAPI";
import { setPagination } from "store/superAdminSlice";

export function useDebounce(value, delay = 500) {
  const [debouncedValue, setDebouncedValue] = useState(value || "");

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const usePendingApprovalData = ({ keyword, statePage }) => {
  const dispatch = useDispatch();

  const query = useSuperAdminGetPendingApproval({
    keyword,
    limit: statePage.rowsPerPage,
    page: statePage.currentPage,
  });

  useEffect(() => {
    if (!query.data?.totalDocs) return;

    dispatch(setPagination({ ...statePage, totalData: query.data.totalDocs }));
  }, [query.data]);

  return query;
};
