import * as yup from "yup";

export const validationCreateSchema = yup.object().shape({
  holidayData: yup.array().of(
    yup.object().shape({
      holidayName: yup.string().required("Holiday Name is Required"),
      startDate: yup.string().required("Start Date is Required"),
      country: yup.string().required("Country is Required"),
      endDate: yup.string().required("End Date is Required"),
      holidayType: yup.string().required("Holiday Type is Required"),
      //   notes: yup.string().required("Description is Required"),
    })
  ),
});

export const validationEditSchema = yup.object().shape({
  holidayData: yup.array().of(
    yup.object().shape({
      holidayName: yup.string().required("Holiday Name is Required"),
      startDate: yup.string().required("Start Date is Required"),
      country: yup.string().required("Country is Required"),
      endDate: yup.string().required("End Date is Required"),
      holidayType: yup.string().required("Holiday Type is Required"),
      //   notes: yup.string().required("Description is Required"),
    })
  ),
});
