import { ModalConfirmationV3 } from "@bluesilodev/timhutcomponents";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useDeleteUser } from "service/userAPI";
import { alertError, alertSuccess } from "utils/alert";

import FillTableMenu from "components/FillTableMenu";
import { adminAndSuperVisorRole } from "utils/roleFromLocalStorage";

function TableMenu({ data }) {
  const [show, setShow] = useState(false);
  const [uIdData, setuIdData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { currentRole } = useSelector((state) => state.loginSlice);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleShow = () => {
    setShow(!show);
  };

  const onEditEmployee = (uId) => {
    navigate(`/editEmployee/${uId}`);
  };

  // DELETE USER
  const { mutate, isPending } = useDeleteUser(uIdData, {
    onSuccess: () => {
      queryClient.invalidateQueries("getUser");
      alertSuccess("Employee deleted successfully");
    },
    onError: () => {
      alertError("FAILED DELETE DATA!");
    },
  });

  const onDeleteEmployee = (uId) => {
    setShow(false);
    setuIdData(uId);
    setShowModal(true);
  };

  const confirmModal = () => {
    mutate(uIdData);
    setShowModal(false);
    setShow(false);
  };

  const cancelModal = () => {
    setShowModal(false);
    setShow(false);
  };

  const detailEmployee = (id) => {
    navigate(`/detailEmployee/${id}`);
  };

  if (isPending) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="relative z-100">
        <FillTableMenu
          currentRole={currentRole}
          handleShow={handleShow}
          onDeleteEmployee={() => onDeleteEmployee(data?.uId)}
          onEditEmployee={() => onEditEmployee(data?.uId)}
          detailEmployee={() => detailEmployee(data?.uId)}
          showSetting={show}
          setShow={setShow}
          dataUserUID={data?.uId}
        />

        {showModal && adminAndSuperVisorRole && (
          <ModalConfirmationV3
            title="Delete Employee"
            content={
              <div className="w-full text-lg font-light text-left">
                Are you sure want to delete employee "
                {data?.lastName
                  ? `${data?.firstName} ${data?.lastName}`
                  : `${data?.firstName}`}
                "?
              </div>
            }
            size="lg"
            onCancel={cancelModal}
            onConfirm={confirmModal}
            confirmLabel="Delete"
            cancelButtonClassname="text-sm"
            confirmButtonClassname="text-sm"
          />
          // <ModalConfirmationV2
          //   className={"min-w-[600px]"}
          //   title={"Delete Employee"}
          //   headMessage={""}
          //   message={
          //     <div className="w-full text-lg font-light text-left">
          //       Do you want to delete this item?
          //     </div>
          //   }
          //   onClose={cancelModal}
          //   onConfirm={confirmModal}
          // />
        )}
      </div>
    </>
  );
}

export default TableMenu;
