import React from "react";
import { ModalDialog, Button } from "@bluesilodev/timhutcomponents";
import { FiCheck, CloseRoundSvg } from "components/icons";

function ModalSyncData({ handleCloseSyncModal }) {
  const submitCondition = () => {
    console.log("SUBMIT");

    window.location.reload();
  };

  return (
    <ModalDialog
      className={"w-[600px]"}
      title={"Sync Data Confirmation"}
      onClose={handleCloseSyncModal}
      onSubmit={submitCondition}
      children={
        <div className="flex flex-col gap-3">
          <h1 className="font-semibold">
            Are you sure you want to sync the data ?
          </h1>

          <p>
            Syncing may cause updates, modifications, or deletions of existing
            records. Ensure you have reviewed all changes before proceeding.
          </p>

          <p className="text-[#B83C3C]">This action cannot be undone.</p>

          <div className="flex justify-between w-full gap-5">
            <Button
              type={"button"}
              onClick={handleCloseSyncModal}
              label={
                <div className="flex gap-2">
                  <CloseRoundSvg />
                  <div className="text-[#DD7224]">Cancel</div>
                </div>
              }
              // className={" btn-secondary-solid "}
              style="outlined"
            />

            <Button
              type={"button"}
              onClick={submitCondition}
              label={
                <div className="flex gap-2 items-center">
                  <FiCheck />
                  <div className="text-white">Submit</div>
                </div>
              }
              // className={" btn-secondary-solid "}
              style="solid"
            />
          </div>
        </div>
      }
    />
  );
}

export default ModalSyncData;
