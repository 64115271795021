import React from "react";
import { Button, InputSelect } from "@bluesilodev/timhutcomponents";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";

import { useGetAllCountry } from "service/commonAPI";
import { FiRefresh } from "components/icons";

function AllButton({
  company,
  year,
  handleOnchangeCompany,
  handleChangeYear,
  handlleSyncModal,
}) {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { currentRole } = useSelector((state) => state.loginSlice);

  const { data: dataCountry, isLoading: loadingCountry } = useGetAllCountry({
    onSuccess: () => {
      queryClient.invalidateQueries("getCountry");
    },
    onError: (err) => {
      console.log("COUNTRY : ", err);
    },
  });

  if (loadingCountry) {
    return <div>Loading...</div>;
  }

  const allCountry =
    dataCountry.data.length > 0 &&
    dataCountry.data.map((item) => {
      return {
        label: item.name,
        value: item.name,
      };
    });

  return (
    <div className="flex justify-between w-full items-center ">
      <div className="min-w-[450px]  flex gap-3 ">
        <InputSelect
          value={company}
          onChange={handleOnchangeCompany}
          options={allCountry}
          title={"Country"}
        />
        <InputSelect
          options={Array(50)
            .fill()
            .map((_, idx) => 1980 + idx)
            .map((val) => ({ label: val, value: val }))}
          title={"Year"}
          classname={"max-w-[170px]"}
          value={year}
          onChange={handleChangeYear}
        />
      </div>

      <div className="flex gap-3 max-w-[300px] ">
        <Button
          type={"button"}
          onClick={handlleSyncModal}
          label={
            <div className="flex justify-center items-center">
              <FiRefresh />
            </div>
          }
          className={"min-w-[100px]"}
          style="outlined"
        />

        {(currentRole === "Admin" || currentRole === "Supervisor") && (
          <Button
            type={"button"}
            onClick={() => navigate("/addHoliday")}
            label={
              <div className="flex gap-2">
                {/* <FiPlusCircle color={"#A3A3AB"} /> */}
                <div className="text-white">Add New Holiday</div>
              </div>
            }
            // className={" btn-secondary-solid "}
            style="solid"
          />
        )}
      </div>
    </div>
  );
}

export default AllButton;
