import React from "react";
import {
  Button,
  Accordion,
  InputText,
  InputSelect,
  CountrySelect,
  ColorPicker,
} from "@bluesilodev/timhutcomponents";
import { Formik } from "formik";
import { useQueryClient } from "@tanstack/react-query";

import { useGetAllCountry } from "service/commonAPI";

import { FiSaveSvg } from "components/icons";
import DepartmentArr from "./DepartmentArr";

function FormSharedLocation({ initialValues, handleSubmit, validationSchema }) {
  const queryClient = useQueryClient();
  const { data: resCountry, isPending } = useGetAllCountry({
    onSuccess: () => {
      queryClient.invalidateQueries("getCountry");
    },
  });

  if (isPending) {
    return <div>Loading...</div>;
  }

  const countryData = resCountry?.data.map((country, _) => {
    return {
      label: country.name,
      value: country.country_code,
    };
  });

  return (
    <div className="overflow-y-auto  ">
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {({
          values,
          handleBlur,
          handleSubmit,
          errors,
          handleChange,
          touched,
          setFieldValue,
        }) => {
          return (
            <div>
              <div className="w-full flex justify-end pt-3">
                <Button
                  className={"w-[200px]"}
                  type={"submit"}
                  onClick={handleSubmit}
                  style="solid"
                  label={
                    <div className="flex gap-2">
                      <FiSaveSvg />
                      <h1>Save</h1>
                    </div>
                  }
                />
              </div>

              <div className="w-full mt-10 max-h-[460px] overflow-auto ">
                <div>
                  <Accordion
                    title={"Location Details"}
                    icons={[]}
                    children={
                      <div className="pt-5 flex flex-col gap-3">
                        <div className="flex gap-3 w-full">
                          <div className="flex gap-3 w-[65%]">
                            <InputText
                              id="locationName"
                              onChange={handleChange}
                              placeholder="Enter Location Name"
                              value={values.locationName}
                              onBlur={handleBlur}
                              title={"Location Name"}
                              label={null}
                              error={
                                <div>
                                  {errors.locationName &&
                                    touched.locationName &&
                                    errors.locationName}
                                </div>
                              }
                            />
                            <InputSelect
                              options={countryData}
                              title={"Country"}
                              id="country"
                              onChange={handleChange}
                              value={values.country}
                              onBlur={handleBlur}
                              error={
                                <div>
                                  {errors.country &&
                                    touched.country &&
                                    errors.country}
                                </div>
                              }
                            />
                          </div>
                          <div className="w-[35%]">
                            <InputText
                              title={"Postal Code"}
                              placeholder="Enter Postal Code"
                              id="postalCode"
                              type={"number"}
                              onChange={handleChange}
                              value={values.postalCode}
                              onBlur={handleBlur}
                              label={null}
                              error={
                                <div>
                                  {errors.postalCode &&
                                    touched.postalCode &&
                                    errors.postalCode}
                                </div>
                              }
                            />
                          </div>
                        </div>

                        <div className="w-full flex gap-3 ">
                          <div className="w-[65%] flex gap-3">
                            <CountrySelect
                              isTitle={false}
                              classname={""}
                              onChange={(value) =>
                                setFieldValue("countryPhoneNumber", value.code)
                              }
                              value={`${values.countryPhoneNumber}`}
                              error={
                                <div>
                                  {errors.countryPhoneNumber &&
                                    touched.countryPhoneNumber &&
                                    errors.countryPhoneNumber}
                                </div>
                              }
                            />
                            <InputText
                              type={"number"}
                              label={null}
                              title={"Phone Number"}
                              placeholder="Enter Phone Number"
                              id="phoneNumber"
                              onChange={handleChange}
                              value={values.phoneNumber}
                              onBlur={handleBlur}
                              error={
                                <div>
                                  {errors.phoneNumber &&
                                    touched.phoneNumber &&
                                    errors.phoneNumber}
                                </div>
                              }
                            />
                          </div>
                          <div className="w-[35%]">
                            <ColorPicker
                              name={"color"}
                              label={"Colors for Organization Chart"}
                              value={values.color}
                              onChange={handleChange}
                              setFieldValue={setFieldValue}
                            />
                          </div>
                        </div>

                        <div className="w-[65%]">
                          {/* <TextArea label={"Address"} /> */}
                          <textarea
                            id="address"
                            onChange={handleChange}
                            value={values.address}
                            onBlur={handleBlur}
                            placeholder="Company Address"
                            className="border-[1px] border-black h-[150px] w-full resize-none rounded-md p-3 outline-none"
                          ></textarea>
                          {errors.address && touched.address && (
                            <div className="text-red-500">{errors.address}</div>
                          )}
                        </div>
                      </div>
                    }
                  />
                </div>

                <DepartmentArr />
              </div>
            </div>
          );
        }}
      </Formik>
    </div>
  );
}

export default FormSharedLocation;
