import React from "react";
import FormHolidayShare from "pages/shared/formHoliday";
import { useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate } from "react-router-dom";

import { validationCreateSchema } from "./validationSchema";
import { useUpdateHoliday, useGetByIdHoliday } from "service/holidayAPI";
import { alertSuccess } from "utils/alert";
import { formatCalendarDate } from "configures/formatDate";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { formatDateISO } from "utils/common";

dayjs.extend(utc);

function EditHolidays() {
  const initialValues = {
    holidayData: [
      {
        holidayName: "",
        startDate: "",
        endDate: "",
        country: "",
        holidayType: "",
        notes: "",
        year: "",
      },
    ],
  };
  const navigate = useNavigate();
  const { uId } = useParams();

  const queryClient = useQueryClient();

  const { data: dataHoliday, isLoading: loadingByidHoliday } =
    useGetByIdHoliday(uId);

  const { mutate: updateHoliday, isPending: loadingUpdateHoliday } =
    useUpdateHoliday({
      onSuccess: () => {
        queryClient.invalidateQueries("getAllHoliday");
        alertSuccess("Update Holiday Success!");
        navigate("/holiday");
      },
      onError: (err) => {
        console.log("Something Wrong !", err);
      },
    });

  if (loadingUpdateHoliday || loadingByidHoliday) {
    return <div>Loading...</div>;
  }

  const generateAllholiday = {
    ...dataHoliday.data,
    startDate: formatCalendarDate(dataHoliday.data.startDate),
    endDate: formatCalendarDate(dataHoliday.data.endDate),
  };

  const allHoliday = dataHoliday.data && [generateAllholiday];

  const setToApi = {
    ...initialValues,
    holidayData: allHoliday || [],
  };

  const handleSubmit = (val, { resetForm }) => {
    const allVal = val.holidayData.map((item) => {
      const [dd, mm, yy] = item.startDate.split("/");
      const startDate = new Date(yy, mm - 1, dd);
      const [ddEnd, mmEnd, yyEnd] = item.endDate.split("/");
      const endDate = new Date(yyEnd, mmEnd - 1, ddEnd);

      return {
        ...item,
        startDate: formatDateISO(startDate),
        endDate: formatDateISO(endDate),
        year: item.year || new Date(item.startDate).getUTCFullYear(),
        notes: item.notes || "",
      };
    });

    updateHoliday(allVal);

    // resetForm();
  };

  return (
    <div>
      <FormHolidayShare
        initialValues={setToApi}
        validationSchema={validationCreateSchema}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}

export default EditHolidays;
