import React, { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import { useCreateUser } from "service/userAPI";
import { useGetAllCountry } from "service/commonAPI";
import { usePostUserInform } from "service/userInformationAPI";

import FormShared from "pages/shared/formUser/FormShared";
import { alertSuccess, alertError } from "utils/alert";

import validationSchemaAddEmployee from "./validationSchemaAddEmployee";
import SuperAdminEmployeeMentDetails from "./SuperAdminEmployeeMentDetails";
import SuperAdminPersonalInfo from "./SuperAdminPersonalInfo";
import SuperAdminIdentificationDoc from "./SuperAdminIdentificationDoc";
import SuperAdminPaymentDetails from "./SuperAdminPaymentDetails";
import { getValidationSchemaAddEmployee } from "pages/employee/addEmployee/validationSchemaAddEmployee";

function SuperAdminAddEmployee() {
  // DATA STUDY
  const { study, leaveData } = useSelector((state) => state.employeeSlice);
  const { currentUser } = useSelector((state) => state.loginSlice);

  const [currentStep, setCurrentStep] = useState(1);
  const [countries, setCountries] = useState([]);

  const queryClient = useQueryClient();
  const location = useLocation();
  const navigate = useNavigate();

  const initialValues = {
    // DATA USER
    employeeID: "",
    organizationID: "",
    userInformationID: "",
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    countryPhoneNumber: "65",
    phoneNumber: "",
    photo: "",
    gender: "",
    bloodTypes: "",
    religions: "",
    dateOfBirth: "",
    maritalStatus: "",
    address: "",
    country: "",
    postalCode: "",
    role: [],
    reportTo: "",

    // USER INFORMATION
    employeementDetail: {
      employeeStatus: "",
      locations: "",
      remoteLocation: "",
      joinDate: "",
      endDate: "",
      departments: "",
      leaveDetail: {
        leave: false,
        leaveSettings: [],
      },
      jobPosition: "",
      isPermanentEmployee: false,
    },

    hobbies: [],
    skills: [],
    languages: [],

    identificationDocument: {
      identificationType: "",
      identityExpiredDate: "",
      nationality: "",
      identityNumber: "",
      isPermanentDate: false,
      covid19VaksinationStatus: "",
      attachments: "",
    },
    paymentDetail: {
      rateOfPays: "",
      banks: "",
      basicSalary: "",
      currencies: "",
      numberAccount: "",
      allowOvertime: false,
      paymentMethods: "",
      accountName: "",
      groupContribution: "",
      cpfAccountNumber: "",
      sdlApplicable: false,
      fwlApplicable: false,
      additionalComponents: [],
      monthlyAllowances: [
        // {
        //   typeOfAllowance: "",
        //   amount: "",
        // },
      ],
    },
  };
  const { data: resCountry, isSuccess: isSuccessCountry } = useGetAllCountry({
    onSuccess: () => {
      queryClient.invalidateQueries("getCountry");
    },
  });

  useEffect(() => {
    if (isSuccessCountry && resCountry.data) {
      setCountries(
        resCountry.data.map((country) => ({
          label: country.name,
          value: country.country_code,
        }))
      );
    }
  }, [isSuccessCountry, resCountry]);

  const handlePlus = (values, setTouched, setFieldTouched, errors) => {
    const {
      organizationID,
      employeeID,
      firstName,
      lastName,
      userName,
      email,
      password,
      confirmPassword,
      countryPhoneNumber,
      phoneNumber,
      photo,
      gender,
      bloodTypes,
      religions,
      dateOfBirth,
      maritalStatus,
      address,
      country,
      postalCode,
      hobbies,
      skills,
      languages,
      role,

      employeementDetail,
      identificationDocument,
    } = values;

    if (currentStep === 1) {
      if (
        organizationID.trim() === "" ||
        firstName.trim() === "" ||
        userName.trim() === "" ||
        /^[A-Za-z0-9 _-]+$/.test(userName) === false ||
        email.trim() === "" ||
        password.trim() === "" ||
        confirmPassword.trim() === "" ||
        countryPhoneNumber === "" ||
        phoneNumber === "" ||
        phoneNumber === null ||
        gender.trim() === "" ||
        bloodTypes.trim() === "" ||
        religions.trim() === "" ||
        maritalStatus.trim() === "" ||
        // country.trim() === "" ||
        // postalCode === "" ||
        // postalCode === null ||
        dateOfBirth.trim() === ""
        // address.trim() === "" ||
        // photo.length === 0 ||
        // hobbies.length === 0 ||
        // skills.length === 0 ||
        // languages.length === 0
      ) {
        setTouched({
          organizationID: true,
          firstName: true,
          userName: true,
          email: true,
          password: true,
          confirmPassword: true,
          phoneNumber: true,
          countryPhoneNumber: true,
          gender: true,
          bloodTypes: true,
          religions: true,
          maritalStatus: true,
          country: true,
          postalCode: true,
          hobbies: true,
          skills: true,
          languages: true,
          // photo: true,
          address: true,
          dateOfBirth: true,
        });
        return;
      } else {
        if (password !== confirmPassword) return;
        setCurrentStep(currentStep + 1);
        return;
      }
    } else if (currentStep === 2) {
      if (errors?.role || errors?.employeeID || errors?.employeementDetail) {
        setFieldTouched("role", true);
        setFieldTouched("employeeID", true);
        setFieldTouched("employeementDetail.departments", true);
        setFieldTouched("employeementDetail.employeeStatus", true);
        setFieldTouched("employeementDetail.endDate", true);
        setFieldTouched("employeementDetail.jobPosition", true);
        setFieldTouched("employeementDetail.joinDate", true);
        setFieldTouched("employeementDetail.locations", true);
        setFieldTouched("employeementDetail.remoteLocation", true);
        // setFieldTouched("employeementDetail.reportTo", true);

        // if (
        //   employeementDetail &&
        //   employeementDetail.joinDate &&
        //   employeementDetail &&
        //   employeementDetail.endDate
        // ) {
        //   const join = employeementDetail.joinDate;
        //   const end = employeementDetail.endDate;
        // }
        return;
      } else {
        setCurrentStep(currentStep + 1);
        return;
      }
    } else if (currentStep === 3) {
      if (
        identificationDocument.identificationType.trim() === "" ||
        identificationDocument.identityNumber === "" ||
        identificationDocument.identityNumber === null ||
        identificationDocument.nationality.trim() === "" ||
        // identificationDocument.covid19VaksinationStatus.trim() === "" ||
        errors?.identificationDocument?.identityExpiredDate
        // identificationDocument.attachments.length === 0
      ) {
        setFieldTouched("identificationDocument.identificationType", true);
        setFieldTouched("identificationDocument.identityNumber", true);
        setFieldTouched("identificationDocument.nationality", true);
        setFieldTouched(
          "identificationDocument.covid19VaksinationStatus",
          true
        );
        setFieldTouched("identificationDocument.identityExpiredDate", true);
        setFieldTouched("identificationDocument.attachments", true);
        return;
      } else {
        setCurrentStep(currentStep + 1);
        return;
      }
    }
  };

  // API USER
  const { mutateAsync: createUser, isPending: loadingUser } = useCreateUser({
    onSuccess: () => {
      queryClient.invalidateQueries("getUser");
      alertSuccess("Employee added successfully ");
      navigate("/super/employee");
    },
    onError: () => {
      alertError("CREATE USER FAILED!");
    },
  });

  // API USER INFORMATION
  const { mutateAsync: createUserInform, isPending: loadingUserInform } =
    usePostUserInform({
      onSuccess: () => {
        queryClient.invalidateQueries("getUser");
        alertSuccess("Employee added successfully ");
      },
      onError: () => {
        alertError("CREATE USERINFORM FAILED!");
      },
    });

  // SUBMIT FORM
  const handleSubmitForm = async (values, { resetForm }) => {
    const formUser = new FormData();
    const formUserInformation = new FormData();

    const [day, month, year] = values.dateOfBirth.split("/");
    const dateOfBirth = new Date(year, month - 1, day);

    const [dd, mm, yy] = values.employeementDetail.joinDate.split("/");
    const joinDate = new Date(yy, mm - 1, dd);

    const [dayEndDate, monthEndDate, yearEndDate] =
      values.employeementDetail.endDate.split("/");
    const endDate = new Date(yearEndDate, monthEndDate - 1, dayEndDate);

    const [dayIdentity, monthIdentity, yearIdentity] =
      values.identificationDocument.identityExpiredDate.split("/");
    const identityExpiredDate = new Date(
      yearIdentity,
      monthIdentity - 1,
      dayIdentity
    );

    const employeementDetail = JSON.stringify({
      ...values.employeementDetail,
      joinDate: joinDate,
      endDate: endDate,
    });
    const identification = JSON.stringify({
      ...values.identificationDocument,
      identityExpiredDate: identityExpiredDate,
    });

    const payment = JSON.stringify(values.paymentDetail);
    const hobby = JSON.stringify(values.hobbies);
    const skill = JSON.stringify(values.skills);
    const language = JSON.stringify(values.languages);

    formUserInformation.append("hobbies", hobby);
    formUserInformation.append("skills", skill);
    formUserInformation.append("languages", language);

    if (study.length > 0) {
      const studyToString = JSON.stringify(study);
      formUserInformation.append("education", studyToString);
    }

    formUserInformation.append("employeementDetail", employeementDetail);
    formUserInformation.append("identificationDocument", identification);
    formUserInformation.append("paymentDetail", payment);

    if (leaveData.length > 0) {
      const parseToString = JSON.stringify(leaveData);
      formUserInformation.append("leaveSettings", parseToString);
    }

    formUserInformation.append(
      "attachments",
      values.identificationDocument.attachments
    );

    try {
      const resp = await createUserInform(formUserInformation);

      if (resp) {
        const userInformationID = resp.data.uId;
        formUser.append("organizationID", values.organizationID);
        formUser.append("employeeID", values.employeeID);
        formUser.append("userInformationID", userInformationID);
        formUser.append("firstName", values.firstName);
        formUser.append("lastName", values.lastName);
        formUser.append("userName", values.userName);
        formUser.append("email", values.email);
        formUser.append("password", values.password);
        formUser.append("countryPhoneNumber", values.countryPhoneNumber);
        formUser.append("phoneNumber", values.phoneNumber);
        formUser.append("photo", values.photo);
        formUser.append("gender", values.gender);
        formUser.append("bloodTypes", values.bloodTypes);
        formUser.append("religions", values.religions);
        formUser.append("dateOfBirth", dateOfBirth);
        formUser.append("maritalStatus", values.maritalStatus);
        formUser.append("address", values.address);
        formUser.append("country", values.country);
        formUser.append("postalCode", values.postalCode);
        formUser.append("role", JSON.stringify(values.role));
        formUser.append("reportTo", values.reportTo);

        if (leaveData.length > 0) {
          const parseToString = JSON.stringify(leaveData);
          formUser.append("leaveSettings", parseToString);
        }

        await createUser(formUser);
      }
    } catch (error) {
      console.log("err : ", error);
    }

    // resetForm();
    // setCurrentStep(1);
  };

  if (loadingUserInform || loadingUser) {
    return <h1>Loading...</h1>;
  }

  return (
    <FormShared
      currentStep={currentStep}
      handleSubmitForm={handleSubmitForm}
      handlePlus={handlePlus}
      setCurrentStep={setCurrentStep}
      validationSchema={getValidationSchemaAddEmployee({
        isAdminOrSuperAdmin: true,
      })}
      initialValues={initialValues}
    >
      {/* {currentStep === 1 && <SuperAdminPersonalInfo countries={countries} />}
      {currentStep === 2 && <SuperAdminEmployeeMentDetails />}
      {currentStep === 3 && <SuperAdminIdentificationDoc />}
      {currentStep === 4 && <SuperAdminPaymentDetails />} */}
      <div className={`${currentStep !== 1 && "hidden"} `}>
        <SuperAdminPersonalInfo countries={countries} />
      </div>
      <div className={`${currentStep !== 2 && "hidden"}`}>
        <SuperAdminEmployeeMentDetails />
      </div>
      <div className={`${currentStep !== 3 && "hidden"}`}>
        <SuperAdminIdentificationDoc />
      </div>
      <div className={`${currentStep !== 4 && "hidden"}`}>
        <SuperAdminPaymentDetails />
      </div>
    </FormShared>
  );
}

export default SuperAdminAddEmployee;
