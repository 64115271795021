// import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { useGetAllLocation } from "service/locationAPI";
import { useGetAllUser } from "service/userAPI";
import { setPagination } from "store/employeeSlice";

export const useEmployeeData = ({ filters, statePage, currentRole }) => {
  const dispatch = useDispatch();

  const { data, isSuccess, isPending, isFetching, refetch } = useGetAllUser({
    ...filters,
    rowsPerPage: statePage.rowsPerPage,
    currentPage: statePage.currentPage,
    currentRole,
    onError: (err) => console.error("Employee data error:", err),
  });

  useEffect(() => {
    if (!data?.totalDocs) return;

    dispatch(setPagination({ ...statePage, totalData: data.totalDocs }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return { data, isSuccess, isPending, isFetching, refetch };
};

export const useInfiniteEmployeeData = ({
  filters,
  statePage,
  currentRole,
}) => {};

export const useFilters = () => {
  const [filters, setFilters] = useState({
    keyword: "",
    location: "",
    department: "",
    jobPosition: "",
  });

  useEffect(() => {
    const resetIfDefault = (value) =>
      value?.toLowerCase() === "" ? "" : value;

    setFilters((prev) => ({
      ...prev,
      location: resetIfDefault(prev.location),
      department: resetIfDefault(prev.department),
      jobPosition: resetIfDefault(prev.jobPosition),
    }));
  }, [filters.location, filters.department, filters.jobPosition]);

  const handleFilterChange = (type, value) => {
    setFilters((prev) => ({
      ...prev,
      [type]: value,
      // Reset dependent filters when parent changes
      ...(type === "location" && { department: "", jobPosition: "" }),
      ...(type === "department" && { jobPosition: "" }),
    }));
  };

  return { filters, handleFilterChange };
};

export const useLocationData = (filters) => {
  const { data: locationData } = useGetAllLocation();

  const processedData = useMemo(() => {
    if (!locationData?.data)
      return { locationData: [], departmentData: [], jobPositionData: [] };

    // Get unique locations
    const locations = [
      ...new Set(locationData.data.map((item) => item.locationName)),
    ].map((location) => ({ value: location, label: location }));

    // Get departments filtered by selected location
    const departments = locationData.data
      .filter(
        (item) => !filters.location || item.locationName === filters.location
      )
      .flatMap((item) => item.departments)
      .map((dept) => dept.department)
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((dept) => ({ value: dept, label: dept }));

    // Get job positions filtered by location and department
    const jobPositions = locationData.data
      .filter(
        (item) => !filters.location || item.locationName === filters.location
      )
      .flatMap((item) => item.departments)
      .filter(
        (dept) => !filters.department || dept.department === filters.department
      )
      .flatMap((dept) => dept.jobPosition)
      .filter((value, index, self) => self.indexOf(value) === index)
      .map((job) => ({ value: job, label: job }));

    return {
      locationData: locations,
      departmentData: departments,
      jobPositionData: jobPositions,
    };
  }, [locationData, filters.location, filters.department]);

  return processedData;
};
