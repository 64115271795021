import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

// import { adminAndSuperVisorRole } from "utils/roleFromLocalStorage";

import FillTableMenu from "components/FillTableMenu";
import { cn } from "utils/common";

function TableMenu({ data }) {
  const [show, setShow] = useState(false);
  const isGridMode = useSelector((state) => state.superAdminSlice.isGridMode);
  const { currentRole } = useSelector((state) => state.loginSlice);
  const navigate = useNavigate();

  const handleShow = () => {
    setShow(!show);
  };

  const onDetail = (uId) => {
    navigate(`/super/pendingApproval/${uId}`);
  };

  return (
    <div className="relative z-100 hover:cursor-pointer">
      <FillTableMenu
        currentRole={currentRole}
        handleShow={handleShow}
        detailEmployee={() => onDetail(data?.uId)}
        showSetting={show}
        setShow={setShow}
        dataUserUID={data?.uId}
        className={cn(isGridMode && "text-white")}
      />
    </div>
  );
}

export default TableMenu;
