import React from "react";
import PropTypes from "prop-types";

function DateIcon({ className, color, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      {...props}
    >
      <path
        d="M1.3335 6.50065C1.3335 5.24332 1.3335 4.61532 1.72416 4.22465C2.11483 3.83398 2.74283 3.83398 4.00016 3.83398H12.0002C13.2575 3.83398 13.8855 3.83398 14.2762 4.22465C14.6668 4.61532 14.6668 5.24332 14.6668 6.50065C14.6668 6.81465 14.6668 6.97198 14.5695 7.06998C14.4715 7.16732 14.3135 7.16732 14.0002 7.16732H2.00016C1.68616 7.16732 1.52883 7.16732 1.43083 7.06998C1.3335 6.97198 1.3335 6.81398 1.3335 6.50065ZM1.3335 12.5007C1.3335 13.758 1.3335 14.386 1.72416 14.7767C2.11483 15.1673 2.74283 15.1673 4.00016 15.1673H12.0002C13.2575 15.1673 13.8855 15.1673 14.2762 14.7767C14.6668 14.386 14.6668 13.758 14.6668 12.5007V9.16732C14.6668 8.85332 14.6668 8.69598 14.5695 8.59798C14.4715 8.50065 14.3135 8.50065 14.0002 8.50065H2.00016C1.68616 8.50065 1.52883 8.50065 1.43083 8.59798C1.3335 8.69598 1.3335 8.85398 1.3335 9.16732V12.5007Z"
        fill={color}
      />
      <path
        d="M4.66699 2.5V4.5M11.3337 2.5V4.5"
        // stroke="#FDFDFD"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
}

DateIcon.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
};

DateIcon.defaultProps = {
  color: "black",
  className: "w-[20px] h-[20px]",
};

export default DateIcon;
