import React from "react";
import FormHolidayShare from "pages/shared/formHoliday";
import { useQueryClient } from "@tanstack/react-query";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { useNavigate } from "react-router-dom";

import { validationCreateSchema } from "./validationSchema";
import { useCreateHoliday } from "service/holidayAPI";
import { alertSuccess } from "utils/alert";
import { formatDateISO } from "utils/common";

dayjs.extend(utc);

function CreateHolidays() {
  const initialValues = {
    holidayData: [
      {
        holidayName: "",
        startDate: "",
        endDate: "",
        country: "",
        holidayType: "",
        notes: "",
      },
    ],
  };

  const navigate = useNavigate();

  const queryClient = useQueryClient();

  const { mutate: createHoliday, isPending: loadingCreate } = useCreateHoliday({
    onSuccess: () => {
      queryClient.invalidateQueries("getAllHoliday");
      alertSuccess("Create Holiday Success!");

      navigate("/holiday");
    },
    onError: (err) => {
      console.log("Something Wrong !", err);
    },
  });

  if (loadingCreate) {
    return <div>Loading...</div>;
  }

  const handleSubmit = (val, { resetForm }) => {
    const allVal = val.holidayData.map((item) => {
      const [dd, mm, yy] = item.startDate.split("/");
      const startDate = new Date(yy, mm - 1, dd);
      const [ddEnd, mmEnd, yyEnd] = item.endDate.split("/");
      const endDate = new Date(yyEnd, mmEnd - 1, ddEnd);

      const year = dayjs(startDate).format("YYYY");

      return {
        ...item,
        startDate: formatDateISO(startDate),
        endDate: formatDateISO(endDate),
        year: year,
      };
    });

    // console.log("ALL VAL : ", allVal);

    createHoliday(allVal);

    resetForm();
  };

  return (
    <div>
      <FormHolidayShare
        initialValues={initialValues}
        validationSchema={validationCreateSchema}
        handleSubmit={handleSubmit}
      />
    </div>
  );
}

export default CreateHolidays;
