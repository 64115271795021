import React from "react";

function DateDisplay({ dateStr }) {
  if (!dateStr) {
    return <div>-</div>;
  }

  const date = new Date(dateStr);

  const options = {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  };

  const formattedDate = date.toLocaleDateString("en-GB", options);

  return (
    <div>
      <div>{formattedDate}</div>
    </div>
  );
}

export default DateDisplay;
