import { Accordion, Button, DataTable } from "@bluesilodev/timhutcomponents";
import { useQueryClient } from "@tanstack/react-query";
import { useFormikContext } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { FiPlusSvg } from "components/icons";
import { useGetLeaveById, useGetLeaveGlobal } from "service/leaveApi";

import { TableMenuHandler } from "components/tableMenu";
import {
  LeaveTypeAddModalForm,
  LeaveTypeEditModalForm,
} from "pages/shared/formLeaveType";
import EmployeeMentDetailShare from "pages/shared/formUser/EmployeementDetailShare";
import { useDeleteLeaveTypeUserByAdmin } from "service/leaveApi";
import { setEditLeaveData } from "store/employeeSlice";
import { alertError, alertSuccess } from "utils/alert";

function EmployeementDetails({
  canEditLeaveSetting,
  currentRole,
  currentUserID,
}) {
  const { values, handleBlur, handleChange, errors, touched, setFieldValue } =
    useFormikContext();
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const { leaveEditData } = useSelector((state) => state.employeeSlice);
  const leaveTypeDetailsInitialState = {
    leaveTypeId: "",
    name: "",
    defaultAmount: 0,
    gainPerMonth: 0,
    autoCalculate: false,
    isFullLeaveAmount: false,
    expiredCarryOver: 0,
    maxCarryOver: 0,
  };
  const [leaveTypeDetailsEdit, setLeaveTypeDetailsEdit] = useState({
    ...leaveTypeDetailsInitialState,
    periodOfEmployments: [],
  });

  const [addLeaveTypeModal, addLeaveTypeOpen] = useState(false);
  const [leaveTypeDetailsModal, leaveTypeDetailsOpen] = useState(false);
  const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);

  const { uId } = useParams();

  const { data: dataIndividual, isSuccess: succesIndividual } = useGetLeaveById(
    uId,
    {
      onError: (err) => {
        console.log("ERR : ", err);
      },
    }
  );

  const { data: globalData, isSuccess: successGlobal } = useGetLeaveGlobal({
    onSuccess: () => {
      queryClient.invalidateQueries("globalLeave");
    },
    onError: (err) => {
      console.log("ERR: ", err);
    },
  });

  useEffect(() => {
    if (succesIndividual && successGlobal && dataIndividual && globalData) {
      if (
        dataIndividual.data.leaveTypeSettings &&
        dataIndividual.data.leaveTypeSettings.length > 0
      ) {
        dispatch(setEditLeaveData(dataIndividual.data.leaveTypeSettings));
      } else {
        dispatch(setEditLeaveData(globalData.data));
      }

      const leaveTypeOptions = globalData.data.map((item) => ({
        label: item.name,
        value: item.name,
      }));
      setLeaveTypeOptions(leaveTypeOptions);
    }
  }, [succesIndividual, successGlobal, dataIndividual, globalData]);

  const {
    mutate: deleteLeaveTypeUser, // Changed from 'delete'
    isLoading,
    isError,
    data,
  } = useDeleteLeaveTypeUserByAdmin({
    onSuccess: (data) => {
      queryClient.invalidateQueries(["getLeaveDataID", uId]);
      queryClient.invalidateQueries(["globalLeave"]);
      alertSuccess("Leave type deleted successfully");
      queryClient.invalidateQueries("getLeaveDataID");
    },
    onError: (error) => {
      console.error("Failed to delete leave type:", error);
      alertError("Failed to delete leave type");
    },
  });

  const onEditClickDataTable = (findSingleLeaveType) => {
    if (!["Admin", "Supervisor"].includes(currentRole)) return;
    setLeaveTypeDetailsEdit({
      ...findSingleLeaveType,
      name: findSingleLeaveType.name,
      leaveTypeID: findSingleLeaveType.leaveTypeID,
      periodOfEmployments: findSingleLeaveType.periodOfEmployments || [],
    });
    leaveTypeDetailsOpen(true);
  };

  const onDeleteClickDataTable = (data) => {
    if (!["Admin", "Supervisor"].includes(currentRole)) return;

    if (!currentUserID) {
      alertError("User ID is missing. Unable to delete leave type.");
      return;
    }

    deleteLeaveTypeUser({
      name: data.name,
      leaveTypeID: data.uId,
      userID: currentUserID, // ✅ Ensure userID is included
    });
  };

  // COLUMN
  const dataColumn = useMemo(
    () => [
      {
        id: "leaveType",
        accessorFn: (row) => row.name,
        header: () => <span>Leave Type</span>,
        enableSorting: true,
      },
      {
        id: "amount",
        accessorFn: (row) => row.defaultAmount,
        header: () => <span>Default Amount</span>,
        enableSorting: true,
      },
      {
        id: "gainPermont",
        accessorFn: (row) => {
          const defaultAmount = row?.defaultAmount || 0;
          return row.isFullLeaveAmount === true
            ? 0
            : Number((defaultAmount / 12).toFixed(2));
        },
        header: () => <span>Gain Per Month</span>,
        enableSorting: true,
      },
      {
        id: "expired",
        accessorFn: (row) => row.expiredCarryOver,
        header: () => <span>Expired Carry Over</span>,
        enableSorting: true,
      },
      {
        id: "maxCarry",
        accessorFn: (row) => row?.maxCarryOver,
        header: () => <span>Max Carry Over</span>,
        enableSorting: true,
      },
      {
        accessorFn: (row) => row,
        disableSorting: true,
        header: () => {},
        id: "action",
        noPadding: true,
        cell: (status) => {
          const leaveType = status.getValue(); // Get the current leave type object
          return (
            <div className="cursor-pointer w-fit h-[20px]">
              {["Admin", "Supervisor"].includes(currentRole) && (
                <TableMenuHandler
                  show={["Admin", "Supervisor"].includes(currentRole)}
                  onEditClick={() => onEditClickDataTable(leaveType)}
                  onDeleteClick={() => onDeleteClickDataTable(leaveType)}
                />
              )}
            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div className="w-full py-5">
      <EmployeeMentDetailShare
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        touched={touched}
        values={values}
        setFieldValue={setFieldValue}
      />

      <div className="py-5">
        <Accordion
          title={"Current Leave Balance"}
          icons={[
            {
              // Conditionally render the button based on canEditLeaveSetting
              icon: canEditLeaveSetting ? (
                <div className="p-2 ">
                  <Button
                    type={"button"}
                    className={"w-[200px] "}
                    style={"solid"}
                    label={
                      <div className="flex items-center justify-center w-full gap-2 p-2 text-white">
                        <FiPlusSvg
                          className={"text-white w-[20px] h-[20px] "}
                        />
                        <h1>Add New Leave Type</h1>
                      </div>
                    }
                    onClick={(e) => {
                      e.stopPropagation();
                      addLeaveTypeOpen(true);
                    }}
                  />
                </div>
              ) : null,
            },
          ]}
          children={
            <div className="py-5">
              <div className="pt-5">
                <DataTable
                  columns={dataColumn}
                  data={leaveEditData ? leaveEditData : []}
                  maxHeight={450}
                />
              </div>
            </div>
          }
        />
        <LeaveTypeAddModalForm
          addLeaveTypeModal={addLeaveTypeModal}
          addLeaveTypeOpen={addLeaveTypeOpen}
          currentUserID={currentUserID}
          isSelectedOption
          allLeaveType={leaveTypeOptions}
          // canEditLeaveSetting={canEditLeaveSetting}
        />
        <LeaveTypeEditModalForm
          leaveTypeDetailsModal={leaveTypeDetailsModal}
          leaveTypeDetailsOpen={leaveTypeDetailsOpen}
          leaveTypeDetailsEdit={leaveTypeDetailsEdit}
          currentUserID={currentUserID}
        />
      </div>
    </div>
  );
}

export default EmployeementDetails;
