import { ModalConfirmationV3 } from "@bluesilodev/timhutcomponents";
import { useQueryClient } from "@tanstack/react-query";
import { memo, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import ReactDOM from "react-dom";

import FillTableMenu from "components/FillTableMenu";
import { Fi3User, FiMail, FiSmartphone } from "components/icons";
import { useDeleteUser } from "service/userAPI";
import { alertError, alertSuccess } from "utils/alert";
import { adminAndSuperVisorRole } from "utils/roleFromLocalStorage";

function CardGrid({ item }) {
  const [showSetting, setShowSetting] = useState(false);
  const [uIdData, setuIdData] = useState(null);
  const [showModalDelete, setShowModalDelete] = useState(false);
  const { currentRole } = useSelector((state) => state.loginSlice);
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // DELETE USER
  const { mutate, isPending } = useDeleteUser(uIdData, {
    onSuccess: () => {
      queryClient.invalidateQueries(["getInfiniteUser"]);
      // setEmployeeGridData((prev) => prev.filter((i) => i.uId !== uIdData));
      alertSuccess("Employee deleted successfully");
    },
    onError: () => {
      alertError("FAILED DELETE DATA!");
    },
  });

  const onEditEmployee = () => {
    navigate(`/editEmployee/${item.uId}`);
  };

  const onDeleteEmployee = () => {
    setShowSetting(false);
    setuIdData(item.uId);
    setShowModalDelete(true);
  };

  const onDetailEmployee = () => {
    navigate(`/detailEmployee/${item.uId}`);
  };

  const confirmModal = () => {
    mutate(uIdData);
    setShowModalDelete(false);
    setShowSetting(false);
  };

  const cancelModal = () => {
    setShowModalDelete(false);
    setShowSetting(false);
  };

  if (isPending) {
    return <div>Loading...</div>;
  }

  const color = item.locations && item?.locations?.color;

  return (
    <>
      <div className="min-h-[310px] flex-auto shadow-md rounded-[16px]">
        <div
          style={{ backgroundColor: color }}
          className=" h-[100px] rounded-t-[16px] p-3 text-sm"
        >
          <div className="flex justify-between w-full ">
            <div
              disabled
              className="text-white border-none outline-none opacity-100 appearance-none"
            >
              <h1 className="">
                {item.locations && item?.locations?.locationName}
              </h1>
            </div>
            <FillTableMenu
              currentRole={currentRole}
              dataUserUID={item.uId}
              onEditEmployee={onEditEmployee}
              onDeleteEmployee={onDeleteEmployee}
              detailEmployee={onDetailEmployee}
              handleShow={() => setShowSetting(!showSetting)}
              showSetting={showSetting}
              setShow={setShowSetting}
              className="text-white cursor-pointer"
            />
          </div>

          <div className="flex items-center justify-center w-full pt-8">
            <div className="border-white border-[5px] w-[75px] h-[75px] rounded-full flex justify-center items-center">
              <img
                src={item?.photo[0]?.link}
                alt={item?.userName}
                className={`bg-white w-[70px] h-[70px] rounded-full object-cover`}
                loading="lazy"
                width={70}
                height={70}
                decoding="async"
              />
            </div>
          </div>

          <div className="flex flex-col items-center justify-center w-full gap-2 pt-2">
            <h1 className="font-bold">{item?.firstName}</h1>
            <div className="px-3 py-1 bg-gray-300 rounded-lg">
              {item?.role[0]}
            </div>
          </div>

          <div className="flex flex-col gap-2 pt-3">
            <div className="flex gap-1">
              <Fi3User />
              <span className="w-full line-clamp-1">
                {item?.userInformation?.employeementDetail?.departments ||
                  (item.locations &&
                    item?.locations[0]?.departments[0]?.department)}
              </span>
            </div>
            <div className="flex gap-1">
              <FiMail />
              <p className="w-full line-clamp-1">{item?.email}</p>
            </div>
            <div className="flex gap-1">
              <FiSmartphone />
              <p className="w-full line-clamp-1">{item?.phoneNumber}</p>
            </div>
          </div>
        </div>
      </div>
      {showModalDelete &&
        adminAndSuperVisorRole &&
        ReactDOM.createPortal(
          <ModalConfirmationV3
            title="Delete Employee"
            content={
              <div className="w-full text-lg font-light text-left">
                Are you sure want to delete employee "
                {item?.lastName
                  ? `${item?.firstName} ${item?.lastName}`
                  : `${item?.firstName}`}
                "?
              </div>
            }
            size="lg"
            onCancel={cancelModal}
            onConfirm={confirmModal}
            confirmLabel="Delete"
            cancelButtonClassname="text-sm"
            confirmButtonClassname="text-sm"
          />,
          document.body
        )}
    </>
  );
}

export default memo(CardGrid);
