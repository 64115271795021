import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { UserAddIcon } from "components/icons";
import DateDisplay from "pages/shared/employeeList/DateDisplay";
import GridView from "pages/shared/employeeList/GridView";
import {
  useEmployeeData,
  useFilters,
  useLocationData,
} from "pages/shared/employeeList/hooks";
import TableView from "pages/shared/employeeList/TableView";
import { Link } from "react-router-dom";
import { setPagination } from "store/employeeSlice";
import { cn } from "utils/common";
import { adminAndSuperVisorRole } from "utils/roleFromLocalStorage";
import EmployeePageFilters from "./EmployeePageFilters";
import TableMenu from "./TableMenu";

const EMPLOYEE_LIST_COLUMN = [
  {
    id: "fullName",
    accessorFn: (row) => {
      return {
        fullName: row?.firstName + " " + row?.lastName || "",
        photo: row.photo || "",
      };
    },
    cell: (param) => {
      if (param) {
        const valueParam = param.getValue();

        return (
          <div className="flex items-center gap-2">
            <img
              className="w-[30px] h-[30px] rounded-full object-cover"
              src={valueParam?.photo[0]?.link}
              alt="firstNameImg"
            />
            <h1>{valueParam.fullName}</h1>
          </div>
        );
      }
    },
    header: () => <span>Employee</span>,
    enableSorting: true,
  },
  {
    id: "employeeStatus",
    accessorFn: (row) => {
      const employeeStatus =
        row?.employeeStatus && row?.employeeStatus?.employeeType;
      return employeeStatus;
    },
    header: () => <div>Employee status</div>,
    enableSorting: true,
  },
  {
    id: "location",
    accessorFn: (row) => {
      const data = row?.locations && row?.locations?.locationName;
      return data;
    },
    header: () => <span>Location</span>,
    enableSorting: true,
  },
  {
    id: "department",
    accessorFn: (row) => {
      return (
        row?.userInformation &&
        row?.userInformation?.employeementDetail?.departments
      );
    },
    header: () => <span>Department</span>,
    enableSorting: true,
  },
  {
    id: "jobPosition",
    accessorFn: (row) => {
      return (
        row?.userInformation &&
        row?.userInformation?.employeementDetail?.jobPosition
      );
    },
    header: () => <span>Job Position</span>,
    enableSorting: true,
  },
  {
    id: "email",
    accessorFn: (row) => row?.email,
    header: () => <span>Email</span>,
    enableSorting: true,
  },
  {
    id: "dateJoin",
    accessorFn: (row) => {
      return (
        row?.userInformation &&
        row?.userInformation?.employeementDetail?.joinDate
      );
    },
    cell: (param) => {
      const val = param.getValue();
      return <DateDisplay dateStr={val} />;
    },
    header: () => <span>Date Joining</span>,
    enableSorting: true,
  },
  {
    id: "contactNumber",
    accessorFn: (row) => row?.phoneNumber,
    header: () => <span>Contact Number</span>,
    enableSorting: true,
  },
  {
    id: "reportTo",
    accessorFn: (row) => {
      const firstName = row?.reportToDetail?.firstName;

      return firstName ? `${firstName}` : "-";
    },
    header: () => <span>Report To</span>,
    enableSorting: true,
  },
  {
    id: "actionEmployee",
    disableSorting: true,
    header: () => "",
    accessorFn: (row) => {
      return row && row;
    },
    cell: (param) => {
      if (param) {
        const data = param.getValue();

        return (
          <div className="cursor-pointer w-fit">
            <TableMenu data={data} />
          </div>
        );
      }
    },
  },
];

export default function EmployeePage() {
  const [isGridMode, setIsGridMode] = useState(false);
  const dispatch = useDispatch();

  const { statePage } = useSelector((state) => state.employeeSlice);
  const { currentRole } = useSelector((state) => state.loginSlice);

  const { filters, handleFilterChange } = useFilters();
  const { data: employees } = useEmployeeData({
    filters,
    statePage,
    currentRole,
  });

  const { locationData, departmentData, jobPositionData } =
    useLocationData(filters);

  const handlePaginationChange = (type, value) => {
    dispatch(setPagination({ ...statePage, [type]: value }));
  };

  return (
    <div className="h-full">
      <div className="flex justify-between w-full gap-4 mb-5">
        <EmployeePageFilters
          filters={filters}
          locationData={locationData}
          departmentData={departmentData}
          jobPositionData={jobPositionData}
          onFilterChange={handleFilterChange}
          isGridMode={isGridMode}
          setIsGridMode={setIsGridMode}
        />

        {adminAndSuperVisorRole && (
          <Link
            to={"/addEmployee"}
            className="flex items-center justify-center gap-1 px-4 text-sm font-bold text-white border-none rounded-md outline-none bg-secondary border-secondary hover:bg-secondaryHover"
          >
            <UserAddIcon />
            <p className="text-nowrap">Add Employee</p>
          </Link>
        )}
      </div>

      <GridView className={cn(!isGridMode && "hidden")} filters={filters} />

      <TableView
        employees={employees?.data || []}
        columns={EMPLOYEE_LIST_COLUMN}
        pagination={statePage}
        onPaginationChange={handlePaginationChange}
        className={cn(isGridMode && "hidden")}
      />
    </div>
  );
}
