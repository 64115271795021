import { useMemo } from "react";
import { cn } from "utils/common";

const VARIANTS = ["rejected", "approved", "pending"];

export default function Chip({ variant = "pending", className = "" }) {
  if (!VARIANTS.includes(variant)) variant = "pending";

  const label = useMemo(
    () => variant[0].toUpperCase() + variant.slice(1),
    [variant]
  );

  return (
    <span
      className={cn(
        "rounded-2xl px-2 py-0.5",
        variant === "pending" && "text-[#484858] bg-[#D4D4D4]",
        variant === "approved" && "text-green-500 bg-green-500",
        variant === "rejected" && "text-red-500 bg-red-500",
        className
      )}
    >
      {label}
    </span>
  );
}
