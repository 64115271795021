import React from "react";
import { LeaveTypeSettingsForm } from "@bluesilodev/timhutcomponents";
import { useAlertApi } from "service/alertAPI";
import {
  useAddLeaveTypeByUser,
  useAddLeaveTypeUserByAdmin,
  useUpdateLeaveTypeUserByAdmin,
} from "service/leaveApi";
import { useGetAllEmployeeStatus } from "service/employeeStatusAPI";
import { alertSuccess, alertError } from "utils/alert";
import { useQueryClient } from "@tanstack/react-query";

export const LeaveTypeAddModalForm = ({
  addLeaveTypeModal,
  addLeaveTypeOpen,
  currentUserID,
  isSelectedOption,
  allLeaveType,
  canEditLeaveSetting,
}) => {
  const queryClient = useQueryClient();

  const {
    mutate: addLeaveUserType,
    isLoading,
    isError,
    data,
  } = useAddLeaveTypeUserByAdmin({
    onSuccess: (data) => {
      alertSuccess("Leave type added successfully");
      queryClient.invalidateQueries("getLeaveDataID");
      alertSuccessCreateClose();
    },
    onError: (error) => {
      console.error("Failed to add leave type:", error);
      alertError("Failed to add leave type");
    },
  });

  const alertSuccessCreateClose = () => {
    addLeaveTypeOpen(false);
  };

  const handleSave = (values) => {
    const payload = {
      userID: currentUserID,
      name: values.name,
      defaultAmount: values.defaultAmount,
      // gainPerMonth: values.gainPerMonth,
      expiredCarryOver: values.expiredCarryOver,
      maxCarryOver: values.maxCarryOver,
      isFullLeaveAmount: values.isFullLeaveAmount,
      periodOfEmployments: values.periodOfEmployments.map((val) => ({
        from: val.from,
        to: val.to,
        equal: val.equal,
      })),
      assignTo: values?.assignTo || [],
    };

    addLeaveUserType(payload);
  };

  return (
    <React.Fragment>
      {addLeaveTypeModal && (
        <LeaveTypeSettingsForm
          visibleModal={addLeaveTypeModal}
          setVisibleModal={addLeaveTypeOpen}
          initialValue={{ expiredCarryOver: 0 }}
          onSubmit={handleSave}
          type={"add"}
          isSelectedOption
          allLeaveType={allLeaveType}
          disableAssignTo
        />
      )}
    </React.Fragment>
  );
};

export const LeaveTypeEditModalForm = ({
  leaveTypeDetailsModal,
  leaveTypeDetailsOpen,
  leaveTypeDetailsEdit,
  currentUserID,
}) => {
  const queryClient = useQueryClient();

  const {
    mutate: updateLeaveUserType,
    isLoading,
    isError,
    data,
  } = useUpdateLeaveTypeUserByAdmin({
    onSuccess: (data) => {
      alertSuccess("Leave type updated successfully");
      queryClient.invalidateQueries("getLeaveDataID");
      alertSuccessUpdateClose();
    },
    onError: (error) => {
      console.error("Failed to update leave type:", error);
      alertError("Failed to update leave type");
    },
  });

  const alertSuccessUpdateClose = () => {
    leaveTypeDetailsOpen(false);
  };

  // useAlertApi(updateLeaveTypeResults, alertSuccessClose);

  const clickSaveEditLeaveType = (values) => {
    updateLeaveUserType({
      userID: currentUserID,
      leaveTypeID: leaveTypeDetailsEdit.uId,
      name: values.name,
      defaultAmount: values.defaultAmount,
      // gainPerMonth: values.gainPerMonth,
      expiredCarryOver: values.expiredCarryOver,
      maxCarryOver: values.maxCarryOver,
      isFullLeaveAmount: values.isFullLeaveAmount,
      periodOfEmployments: values.periodOfEmployments.map((val) => ({
        from: val.from,
        to: val.to,
        equal: val.equal,
      })),
      assignTo: values?.assignTo || [],
    });
  };

  return (
    <React.Fragment>
      {leaveTypeDetailsModal && (
        <LeaveTypeSettingsForm
          visibleModal={leaveTypeDetailsModal}
          setVisibleModal={leaveTypeDetailsOpen}
          initialValue={leaveTypeDetailsEdit}
          onSubmit={clickSaveEditLeaveType}
          type={"edit"}
          disableAssignTo
        />
      )}
    </React.Fragment>
  );
};
