import { SearchBox } from "@bluesilodev/timhutcomponents";

const PendingApprovalFilters = ({ filters, onFilterChange }) => {
  return (
    <div className="flex w-1/3 gap-4">
      <SearchBox
        value={filters.keyword}
        onChange={(e) => onFilterChange("keyword", e.target.value)}
        placeholder="Search"
      />
    </div>
  );
};

export default PendingApprovalFilters;
