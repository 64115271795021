import "./public-path";

import React from "react";
import ReactDOM from "react-dom/client";
// react query
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { HelmetProvider } from "react-helmet-async";
// redux
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
// query config
import queryConfig from "configures/queryConfig";
import { store, persistor } from "store";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./index.css";

let root = null;

const queryClient = new QueryClient(queryConfig);

const render = (props) => {
  const { container } = props;

  const containerElement = container
    ? container.querySelector("#root")
    : document.querySelector("#root");

  if (!root) {
    root = ReactDOM.createRoot(containerElement);
  }

  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>
            <App />
          </HelmetProvider>
          {process.env.NODE_ENV !== "production" && (
            <ReactQueryDevtools position="bottom-right" />
          )}
        </QueryClientProvider>
      </PersistGate>
    </Provider>
  );
};

if (!window.__POWERED_BY_QIANKUN__) {
  render({});
}

reportWebVitals();

export async function bootstrap() {
  console.log("[react16] react app bootstraped");
}

export async function mount(props) {
  console.log("dashboard props from main framework", props);
  render(props);
}

export async function unmount(props) {
  if (root) {
    root.unmount();
    root = null; // Clean up the root reference
  }
}
