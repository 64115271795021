import { useQuery, useMutation } from "@tanstack/react-query";
import { customAxios } from "utils/axios";
import { getLocalStorage } from "utils/common";

const API = `/api/company`;

const { companyID } = getLocalStorage();

export const useGetAllCompany = ({
  keyword,
  uId,
  onError,
  onSuccess,
  rowsPerPage,
  currentPage,
}) => {
  const fetchData = async () => {
    let queryParams = "?";
    if (keyword) queryParams += `keyword=${keyword}&`;
    if (uId) queryParams += `uId=${uId}&`;
    if (rowsPerPage) queryParams += `limit=${rowsPerPage}&`;
    if (currentPage) queryParams += `page=${currentPage}&`;

    const res = await customAxios.get(`${API}/${queryParams}`);
    return res.data;
  };

  const queryKey = ["getUser"];
  if (keyword) queryKey.push(`keyword=${keyword}`);
  if (uId) queryKey.push(`uId=${uId}`);
  if (rowsPerPage) queryKey.push(`limit=${rowsPerPage}`);
  if (currentPage) queryKey.push(`page=${currentPage}`);

  return useQuery({
    queryKey: queryKey.length > 1 ? queryKey : ["getCompany"],
    queryFn: fetchData,
    onError,
    onSuccess,
  });
};

export const useGetAllCompanyForHoliday = ({ onSuccess, onError }) => {
  return useQuery({
    queryKey: ["getCompanyForHoliday"],
    queryFn: async () => {
      const res = await customAxios.get(`${API}/all`);

      return res.data;
    },
    onSuccess,
    onError,
  });
};

export const useGetCompanyById = ({ onSuccess, onError }) => {
  return useQuery({
    queryKey: ["getCompanyById"],
    queryFn: async () => {
      const res = await customAxios.get(`${API}/${companyID}/`);

      return res.data;
    },
    onSuccess,
    onError,
  });
};

export const useCreateCompany = ({ onSuccess }) => {
  return useMutation({
    mutationFn: async (body) => {
      const res = await customAxios.post(`${API}`, body);

      return res.data;
    },
    onSuccess,
  });
};

export const useDeleteCompany = ({ onSuccess }) => {
  return useMutation({
    mutationFn: async () => {
      const res = await customAxios.delete(`${API}/${companyID}`);

      return res;
    },
    onSuccess,
  });
};

export const useUpdateCompany = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: async (body) => {
      const res = await customAxios.put(`${API}/${companyID}`, body);

      return res.data;
    },
    onSuccess,
    onError,
  });
};

export const useGetMyCompanyById = (companyID, { onSuccess, onError }) => {
  return useQuery({
    queryKey: ["getMyCompanyById"],
    queryFn: async () => {
      const res = await customAxios.get(`${API}/${companyID}/`);

      return res.data;
    },
    onSuccess,
    onError,
  });
};

export const useUpdateMyCompany = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: async ({ companyID, body }) => {
      const res = await customAxios.put(`${API}/${companyID}`, body);

      return res.data;
    },
    onSuccess,
    onError,
  });
};

export const useDeleteMyCompany = ({ onSuccess }) => {
  return useMutation({
    mutationFn: async ({ companyID }) => {
      const res = await customAxios.delete(`${API}/${companyID}`);

      return res;
    },
    onSuccess,
  });
};
