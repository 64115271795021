import React from "react";
import { Button } from "@bluesilodev/timhutcomponents";
import { Formik } from "formik";

import { FiSaveSvg } from "components/icons";

import HolidayArrForm from "./holidayArrForm";

function FormHolidayShare({ initialValues, validationSchema, handleSubmit }) {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ handleSubmit }) => {
        return (
          <div className="flex flex-col">
            <div className="flex justify-end w-full ">
              <Button
                style="solid"
                className={"w-[200px]"}
                onClick={handleSubmit}
                type={"submit"}
                label={
                  <div className="flex gap-2">
                    <FiSaveSvg />
                    <div>Save</div>
                  </div>
                }
              />
            </div>

            <div className="overflow-auto max-h-[calc(100vh-123px)] mt-5">
              <HolidayArrForm />
            </div>
          </div>
        );
      }}
    </Formik>
  );
}

export default FormHolidayShare;
