import clsx from "clsx";
import { twMerge } from "tailwind-merge";
import dayjs from "dayjs";

export const getLocalStorage = () => {
  const payload = JSON.parse(localStorage.getItem("currentUser")) || "";
  const token = localStorage.getItem("accessToken") || "";
  const companyID = payload ? payload?.organizationID : null;

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  return {
    config,
    companyID,
  };
};

export const capitalize_first_letter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export function formatText(input) {
  if (!input) return ""; // Handle string kosong
  // Trim spasi di awal/akhir, lalu format teks
  return input
    .trim() // Menghapus spasi di awal dan akhir string
    .split(" ") // Memisahkan kalimat menjadi array kata
    .map(
      (word, index) =>
        index === 0
          ? word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() // Kata pertama huruf kapital
          : word.toLowerCase() // Kata lainnya huruf kecil
    )
    .join(" "); // Gabungkan kembali menjadi string
}

export function cn(...inputs) {
  return twMerge(clsx(inputs));
}

export function buildQueryString(params) {
  return Object.entries(params)
    .filter(([_, value]) => value !== undefined && value !== null) // Hapus yang undefined/null
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
}

// FORMAT DATE 04 jan 2025
export const formattedDate = (date) => dayjs(date).format("DD MMM YYYY");

// FORMAT DAY OF WEEK
export const getDayOfWeek = (date) => dayjs(date).format("dddd");

export const formatDateISO = (date) => {
  // FORMAT TO "2025-03-29"
  return date.toISOString().split("T")[0];
};
