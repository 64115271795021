import React, { useState, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { useGetAllCountry } from "service/commonAPI";
import { useGetUserById, useEditUser } from "service/userAPI";
import { useUpdateUserInform } from "service/userInformationAPI";

import FormShared from "pages/shared/formUser/FormShared";
import { alertSuccess, alertError } from "utils/alert";

// import validationEditSchema from "./validationEditSchema";
import EmployeementDetails from "./employeementDetail";
import PersonalInfo from "./personalInfo";
import IdentificationDoc from "./identificationDoc";
import PaymentDetails from "./paymentDetails";
import { formatCalendarDate } from "configures/formatDate";
import {
  setCurrentFormStep,
  setDataEditStudy,
  setEmployeeType,
} from "store/employeeSlice";

function DetailEmployee() {
  // EMPLOYEE SLICE
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.loginSlice);

  const [countries, setCountries] = useState([]);
  const { uId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  // DATA EDUCATION FROM REDUX
  const { dataEditStudy, leaveEditData } = useSelector(
    (state) => state.employeeSlice
  );

  // FORM STEP FROM REDUX
  const currentStep = useSelector(
    (state) => state.employeeSlice.currentFormStep
  );
  const setCurrentStep = (step) => dispatch(setCurrentFormStep(step));
  useEffect(() => {
    return () => setCurrentStep(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const organizationID = currentUser?.organizationID;

  const initialValues = {
    // DATA USER
    employeeID: "",
    organizationID: "",
    userInformationID: "",
    firstName: "",
    lastName: "",
    userName: "",
    email: "",
    password: "",
    confirmPassword: "",
    countryPhoneNumber: "65",
    phoneNumber: "",
    photo: "",
    gender: "",
    bloodTypes: "",
    religions: "",
    dateOfBirth: "",
    maritalStatus: "",
    address: "",
    country: "",
    postalCode: "",
    role: [],
    reportTo: "",

    // USER INFORMATION
    employeementDetail: {
      employeeStatus: "",
      locations: "",
      remoteLocation: "",
      // employeeID: "",
      joinDate: "",
      endDate: "",
      departments: "",
      leaveDetail: {
        leave: false,
        leaveSettings: [],
      },
      jobLevel: "",
      jobPosition: "",
      isPermanentEmployee: false,
    },

    hobbies: [],
    skills: [],
    languages: [],

    identificationDocument: {
      identificationType: "",
      identityExpiredDate: "",
      nationality: "",
      identityNumber: "",
      isPermanentDate: false,
      covid19VaksinationStatus: "",
      attachments: "",
    },
    paymentDetail: {
      rateOfPays: "",
      banks: "",
      basicSalary: "",
      currencies: "",
      numberAccount: "",
      allowOvertime: false,
      paymentMethods: "",
      accountName: "",
      groupContribution: "",
      cpfAccountNumber: "",
      sdlApplicable: false,
      fwlApplicable: false,
      additionalComponents: [],
      monthlyAllowances: [
        // {
        //   typeOfAllowance: "",
        //   amount: "",
        // },
      ],
    },
  };

  const { data: resCountry, isSuccess: isSuccessCountry } = useGetAllCountry({
    onSuccess: () => {
      queryClient.invalidateQueries("getCountry");
    },
  });

  useEffect(() => {
    if (isSuccessCountry && resCountry.data) {
      setCountries(
        resCountry.data.map((country) => ({
          label: country.name,
          value: country.country_code,
        }))
      );
    }
  }, [isSuccessCountry, resCountry]);

  const handlePlus = (values, setTouched, setFieldTouched, errors) => {
    const {
      employeeID,
      firstName,
      lastName,
      userName,
      email,
      password,
      confirmPassword,
      phoneNumber,
      countryPhoneNumber,
      photo,
      gender,
      bloodTypes,
      religions,
      dateOfBirth,
      maritalStatus,
      address,
      country,
      postalCode,
      hobbies,
      skills,
      languages,
      role,

      employeementDetail,
      identificationDocument,
    } = values;

    if (currentStep === 1) {
      if (
        firstName.trim() === "" ||
        lastName.trim() === "" ||
        userName.trim() === "" ||
        email.trim() === "" ||
        // password.trim() === "" ||
        // confirmPassword.trim() === "" ||
        countryPhoneNumber === "" ||
        phoneNumber === "" ||
        phoneNumber === null ||
        gender.trim() === "" ||
        bloodTypes.trim() === "" ||
        religions.trim() === "" ||
        maritalStatus.trim() === "" ||
        // country.trim() === "" ||
        // postalCode === "" ||
        // postalCode === null ||
        dateOfBirth.trim() === ""
        // address.trim() === "" ||
        // photo.length === 0 ||
        // hobbies.length === 0 ||
        // skills.length === 0 ||
        // languages.length === 0
      ) {
        setTouched({
          firstName: true,
          lastName: true,
          userName: true,
          email: true,
          // password: true,
          // confirmPassword: true,
          countryPhoneNumber: true,
          phoneNumber: true,
          gender: true,
          bloodTypes: true,
          religions: true,
          maritalStatus: true,
          country: true,
          postalCode: true,
          hobbies: true,
          skills: true,
          languages: true,
          photo: true,
          address: true,
          dateOfBirth: true,
        });
        return;
      } else {
        if (password !== confirmPassword) {
          setTouched({
            password: true,
            confirmPassword: true,
          });
          return;
        }
        setCurrentStep(currentStep + 1);
        return;
      }
    } else if (currentStep === 2) {
      if (
        role.length === 0 ||
        employeeID.trim() === "" ||
        employeementDetail.departments.trim() === "" ||
        employeementDetail.employeeStatus.trim() === "" ||
        employeementDetail.endDate.trim() === "" ||
        employeementDetail.jobPosition.trim() === "" ||
        employeementDetail.joinDate.trim() === "" ||
        employeementDetail.locations.trim() === "" ||
        (errors &&
          errors.employeementDetail &&
          errors.employeementDetail.joinDate) ||
        (errors &&
          errors.employeementDetail &&
          errors.employeementDetail.endDate)
      ) {
        setFieldTouched("role", true);
        setFieldTouched("employeeID", true);
        setFieldTouched("employeementDetail.departments", true);
        setFieldTouched("employeementDetail.employeeStatus", true);
        setFieldTouched("employeementDetail.endDate", true);
        setFieldTouched("employeementDetail.jobPosition", true);
        setFieldTouched("employeementDetail.joinDate", true);
        setFieldTouched("employeementDetail.locations", true);
        return;
      } else {
        setCurrentStep(currentStep + 1);
        return;
      }
    } else if (currentStep === 3) {
      if (
        identificationDocument.identificationType.trim() === "" ||
        identificationDocument.identityNumber === "" ||
        identificationDocument.identityNumber === null ||
        identificationDocument.nationality.trim() === "" ||
        // identificationDocument.covid19VaksinationStatus.trim() === "" ||
        identificationDocument.identityExpiredDate.trim() === ""
        // identificationDocument.attachments.length === 0
      ) {
        setFieldTouched("identificationDocument.identificationType", true);
        setFieldTouched("identificationDocument.identityNumber", true);
        setFieldTouched("identificationDocument.nationality", true);
        setFieldTouched(
          "identificationDocument.covid19VaksinationStatus",
          true
        );
        setFieldTouched("identificationDocument.identityExpiredDate", true);
        setFieldTouched("identificationDocument.attachments", true);
        return;
      } else {
        setCurrentStep(currentStep + 1);
        return;
      }
    }
  };

  // GET USER BY ID
  const {
    data: userData,
    isSuccess,
    isPending: pendingUser,
  } = useGetUserById(uId, {
    onSuccess: () => {
      queryClient.invalidateQueries("getUserById");
    },
  });

  useEffect(() => {
    if (isSuccess && userData) {
      const educationData = userData.data[0].userInformation.education;

      dispatch(setDataEditStudy(educationData));
    }
  }, [isSuccess, userData]);

  // GET USERINFORMATIONID
  const userInformationID = userData?.data[0]?.userInformationID;
  // UPDATE DATA USER
  const { mutateAsync: updateUser, isPending: loadingUpdate } = useEditUser(
    uId,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getUser");
        alertSuccess("Employee updated successfully");
        navigate("/");
      },
      onError: () => {
        alertError("Update Data User Failed!");
      },
    }
  );

  // UPDATE DATA USER INFORMATION
  const { mutateAsync: updateUserInform, isPending: pendingUserInform } =
    useUpdateUserInform(userInformationID, {
      onSuccess: () => {
        queryClient.invalidateQueries("getOneUserInform");
        alertSuccess("Employee updated successfully ");
      },
      onError: () => {
        alertError("Update UserInformation Failed");
      },
    });

  // SUBMIT FORM
  const handleSubmitForm = async (values) => {
    const formUser = new FormData();
    const formUserInformation = new FormData();

    const [dd, mm, yy] = values.employeementDetail.joinDate.split("/");
    const [dayEndDate, monthEndDate, yearEndDate] =
      values.employeementDetail.endDate.split("/");

    const [day, month, year] = values.dateOfBirth.split("/");
    const dateOfBirth = new Date(year, month - 1, day);

    const joinDate = new Date(yy, mm - 1, dd);
    const endDate = new Date(yearEndDate, monthEndDate - 1, dayEndDate);

    const [dayIdentity, monthIdentity, yearIdentity] =
      values.identificationDocument.identityExpiredDate.split("/");
    const identityExpiredDate = new Date(
      yearIdentity,
      monthIdentity - 1,
      dayIdentity
    );

    const employeementDetail = JSON.stringify({
      ...values.employeementDetail,
      joinDate: joinDate,
      endDate: endDate,
    });

    const identifikasi = JSON.stringify({
      ...values.identificationDocument,
      identityExpiredDate: identityExpiredDate,
    });

    const payment = JSON.stringify(values.paymentDetail);
    const hobby = JSON.stringify(values.hobbies);
    const skill = JSON.stringify(values.skills);
    const language = JSON.stringify(values.languages);

    if (dataEditStudy.length > 0) {
      const studyToString = JSON.stringify(dataEditStudy);

      formUserInformation.append("education", studyToString);
    }

    formUserInformation.append("hobbies", hobby);
    formUserInformation.append("skills", skill);
    formUserInformation.append("languages", language);

    formUserInformation.append("employeementDetail", employeementDetail);
    formUserInformation.append("identificationDocument", identifikasi);
    formUserInformation.append("paymentDetail", payment);

    if (leaveEditData.length > 0) {
      const parseToString = JSON.stringify(leaveEditData);
      formUserInformation.append("leaveSettings", parseToString);
    }

    formUserInformation.append(
      "attachments",
      values.identificationDocument.attachments
    );

    try {
      const res = await updateUserInform(formUserInformation);

      const userInformationID = res?.data?.uId;

      if (userInformationID) {
        formUser.append("employeeID", values.employeeID);
        formUser.append("organizationID", values.organizationID);
        formUser.append("userInformationID", userInformationID);
        formUser.append("firstName", values.firstName);
        formUser.append("lastName", values.lastName);
        formUser.append("userName", values.userName);
        formUser.append("email", values.email);
        formUser.append("password", values.password);
        formUser.append("countryPhoneNumber", values.countryPhoneNumber);
        formUser.append("phoneNumber", values.phoneNumber);
        formUser.append("photo", values.photo);
        formUser.append("gender", values.gender);
        formUser.append("bloodTypes", values.bloodTypes);
        formUser.append("religions", values.religions);
        formUser.append("dateOfBirth", dateOfBirth);
        formUser.append("maritalStatus", values.maritalStatus);
        formUser.append("address", values.address);
        formUser.append("country", values.country);
        formUser.append("postalCode", values.postalCode);
        formUser.append("role", JSON.stringify(values.role));
        formUser.append("reportTo", values.reportTo);

        if (leaveEditData.length > 0) {
          const parseToString = JSON.stringify(leaveEditData);
          formUser.append("leaveSettings", parseToString);
        }

        await updateUser(formUser);
      }
    } catch (error) {
      console.log("ERR: ", error);
    }

    setCurrentStep(1);
  };

  if (loadingUpdate || pendingUserInform || pendingUser) {
    return <div>Loading...</div>;
  }

  const apiDataUser = userData.data ? userData?.data[0] : userData?.data;

  // ADDITIONAL COMPONENTS ITEM
  const additionalComponents =
    userData?.data[0].userInformation?.paymentDetail?.additionalComponents ||
    [
      // {
      //   typeOfAllowance: "",
      //   amount: "",
      // },
    ];

  // MONTHLY ALLOWANCES ITEM
  const monthlyAllowances =
    userData?.data[0].userInformation?.paymentDetail?.monthlyAllowances ||
    [
      // {
      //   typeOfAllowance: "",
      //   amount: "",
      // },
    ];

  if (apiDataUser?.employeestatuses[0]?.employeeType) {
    dispatch(setEmployeeType(apiDataUser?.employeestatuses[0]?.employeeType));
  }

  const setToDataApi = {
    ...initialValues,
    employeeID: apiDataUser?.employeeID || "",
    userInformationID: apiDataUser?.userInformationID || "",
    organizationID: apiDataUser?.organizationID || "",
    firstName: apiDataUser.firstName || "",
    lastName: apiDataUser?.lastName || "",
    userName: apiDataUser?.userName || "",
    email: apiDataUser?.email || "",
    password: "",
    confirmPassword: "",
    countryPhoneNumber: apiDataUser?.countryPhoneNumber || "",
    phoneNumber: apiDataUser?.phoneNumber || "",
    photo: apiDataUser?.photo[0]?.link || [],
    gender: apiDataUser?.gender || "",
    bloodTypes: apiDataUser?.bloodTypes || "",
    religions: apiDataUser?.religions || "",
    dateOfBirth: formatCalendarDate(apiDataUser.dateOfBirth) || "",
    maritalStatus: apiDataUser?.maritalStatus || "",
    address: apiDataUser?.address || "",
    country: apiDataUser?.country || "",
    postalCode: apiDataUser?.postalCode || "",
    hobbies: apiDataUser?.userInformation?.hobbies || [],
    skills: apiDataUser?.userInformation?.skills || [],
    languages: apiDataUser?.userInformation?.languages || [],
    role: apiDataUser?.role || [],
    reportTo: apiDataUser?.reportTo || "",
    employeementDetail: {
      employeeStatus:
        apiDataUser?.userInformation?.employeementDetail?.employeeStatus || "",
      departments:
        apiDataUser?.userInformation?.employeementDetail?.departments || "",
      jobPosition:
        apiDataUser?.userInformation?.employeementDetail?.jobPosition || "",
      locations: apiDataUser?.locations?.uId || "",
      remoteLocation:
        apiDataUser?.userInformation?.employeementDetail.remoteLocation,
      // employeeID: "",
      joinDate:
        formatCalendarDate(
          apiDataUser?.userInformation?.employeementDetail?.joinDate
        ) || "",
      endDate:
        formatCalendarDate(
          apiDataUser?.userInformation?.employeementDetail?.endDate
        ) || "",
      isPermanentEmployee:
        apiDataUser?.userInformation?.employeementDetail?.isPermanentEmployee ||
        false,
      // endDate: "-",
    },
    identificationDocument: {
      identificationType:
        apiDataUser?.userInformation?.identificationDocument
          ?.identificationType || "",
      identityExpiredDate:
        formatCalendarDate(
          apiDataUser?.userInformation?.identificationDocument
            ?.identityExpiredDate
        ) || "",
      nationality:
        apiDataUser?.userInformation?.identificationDocument?.nationality || "",
      identityNumber:
        apiDataUser?.userInformation?.identificationDocument?.identityNumber ||
        "",
      isPermanentDate:
        apiDataUser?.userInformation?.identificationDocument?.isPermanentDate ||
        false,
      covid19VaksinationStatus:
        apiDataUser?.userInformation?.identificationDocument
          ?.covid19VaksinationStatus || "",
      attachments:
        apiDataUser?.userInformation?.identificationDocument?.attachments?.[0]
          ?.link || "",
    },
    paymentDetail: {
      rateOfPays: apiDataUser?.userInformation?.paymentDetail?.rateOfPays || "",
      banks: apiDataUser?.userInformation?.paymentDetail?.banks || "",
      basicSalary:
        apiDataUser?.userInformation?.paymentDetail?.basicSalary || "0",
      currencies: apiDataUser?.userInformation?.paymentDetail?.currencies || "",
      numberAccount:
        apiDataUser?.userInformation?.paymentDetail?.numberAccount || "",
      allowOvertime:
        apiDataUser?.userInformation?.paymentDetail?.allowOvertime || false,
      paymentMethods:
        apiDataUser?.userInformation?.paymentDetail?.paymentMethods || "",
      accountName:
        apiDataUser?.userInformation?.paymentDetail?.accountName || "",
      groupContribution:
        apiDataUser?.userInformation?.paymentDetail?.groupContribution || "",
      cpfAccountNumber:
        apiDataUser?.userInformation?.paymentDetail?.cpfAccountNumber || "",
      sdlApplicable:
        apiDataUser?.userInformation?.paymentDetail?.sdlApplicable || false,
      fwlApplicable:
        apiDataUser?.userInformation?.paymentDetail?.fwlApplicable || false,
      additionalComponents:
        // SHOW THE ARRAY IF LENGTH > 0 || USING ARRAY FILL STRING : ""
        additionalComponents.length > 0
          ? additionalComponents
          : [
              // {
              //   typeOfAllowance: "",
              //   amount: "",
              // },
            ],
      monthlyAllowances:
        // SHOW THE ARRAY IF LENGTH > 0 || USING ARRAY FILL STRING : ""
        monthlyAllowances.length > 0
          ? monthlyAllowances
          : [
              // {
              //   typeOfAllowance: "",
              //   amount: "",
              // },
            ],
    },
  };

  return (
    <FormShared
      currentStep={currentStep}
      handleSubmitForm={handleSubmitForm}
      handlePlus={handlePlus}
      setCurrentStep={setCurrentStep}
      //   validationSchema={validationEditSchema}
      initialValues={setToDataApi}
      isDetailData={true}
    >
      <div className={`${currentStep !== 1 && "hidden"} `}>
        <PersonalInfo countries={countries} />
      </div>
      <div className={`${currentStep !== 2 && "hidden"}`}>
        <EmployeementDetails />
      </div>
      <div className={`${currentStep !== 3 && "hidden"}`}>
        <IdentificationDoc />
      </div>
      <div className={`${currentStep !== 4 && "hidden"}`}>
        <PaymentDetails />
      </div>
    </FormShared>
  );
}

export default DetailEmployee;
