import {
  Button,
  DataTable,
  Pagination,
  SearchBox,
} from "@bluesilodev/timhutcomponents";
import { useQueryClient } from "@tanstack/react-query";
import { UserAddIcon } from "components/icons";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetAllCompany } from "service/companyAPI";
import TableMenu from "./TableMenu";

function SuperAdminOrganization() {
  const navigate = useNavigate();
  const inputRef = React.useRef(null);
  const [keyword, setKeyword] = useState("");
  const [paginate, setPaginate] = useState({
    totalData: 0,
    rowsPerPage: 10,
    currentPage: 1,
  });
  const queryClient = useQueryClient();
  const { data, isSuccess, refetch } = useGetAllCompany(
    {
      keyword: keyword,
      rowsPerPage: paginate.rowsPerPage,
      currentPage: paginate.currentPage,
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries("getCompany");
      },
      onError: (err) => {
        console.log("err: ", err);
      },
    }
  );

  React.useEffect(() => {
    data &&
      setPaginate((oldData) => ({ ...oldData, totalData: data?.totalDoc }));
  }, [data]);

  // FETCH DATA IF SUCCES GET API
  if (isSuccess && !data) {
    refetch();
  }

  const dataColumn = [
    {
      id: "organizationName",
      accessorFn: (row) => {
        return {
          companyName: row?.companyName,
          companyImage: row?.companyImage,
        };
      },
      header: () => <span>Organization Name</span>,
      enableSorting: true,
      cell: (param) => {
        if (param) {
          const valueParam = param.getValue();
          if (valueParam.companyImage) {
            return (
              <div className="flex items-center gap-2">
                <img
                  className="w-[30px] h-[30px] rounded-full"
                  src={valueParam?.companyImage[0]?.link}
                  alt="firstNameImg"
                />
                <h1>{valueParam?.companyName}</h1>
              </div>
            );
          }
        }
      },
    },
    {
      id: "organizationUen",
      accessorFn: (row) => {
        return row?.uenNumber;
      },
      header: () => <span>Organization UEN</span>,
      enableSorting: true,
    },
    {
      id: "organizationCreateDate",
      accessorFn: (row) => {
        return row?.createdAt;
      },
      header: () => <span>Organization Create Date</span>,
      enableSorting: true,
      cell: (param) => {
        return <span>{new Date(param.getValue()).toDateString()}</span>;
      },
    },
    {
      id: "organizationTotalCountEmployee",
      accessorFn: (row) => {
        return row?.totalEmployees;
      },
      header: () => <span>Organization Total Count Employee</span>,
      enableSorting: true,
    },
    {
      id: "actionSuperAdminOrganization",
      disableSorting: true,
      header: () => "",
      accessorFn: (row) => {
        return row && row;
      },
      cell: (param) => {
        if (param) {
          const data = param.getValue();
          return (
            <div className="cursor-pointer w-fit">
              <TableMenu data={data} />
            </div>
          );
        }
      },
    },
  ];
  return (
    <React.Fragment>
      <div>
        <div className="w-full pt-4">
          <div className="flex justify-between w-full">
            <SearchBox
              className={"w-1/5 h-[60px]"}
              value={keyword}
              ref={inputRef}
              onChange={(e) => setKeyword(e.target.value)}
              placeholder="Search by name"
            />
            <div className={`flex w-full gap-4`}></div>
            <Button
              onClick={() => {
                navigate("/super/addCompany");
              }}
              style="solid"
              className={" text-white h-full w-1/5"}
              label={
                <div className="flex gap-1 px-4">
                  <UserAddIcon />
                  <div>Add Company </div>
                </div>
              }
            />
          </div>
          <div className="pt-5">
            <DataTable
              title="Organization Data"
              columns={dataColumn}
              data={!data?.data ? [] : data?.data}
              pagination={false}
              className="max-h-[calc(100dvh-305px)]"
            />
            <div className="py-2" />
            <Pagination
              totalData={paginate.totalData}
              rowsPerPage={paginate.rowsPerPage}
              currentPage={paginate.currentPage}
              onChangeCurrentPage={(val) =>
                setPaginate((oldData) => ({ ...oldData, currentPage: val + 1 }))
              }
              onChangeRowsPerPage={(val) =>
                setPaginate((oldData) => ({ ...oldData, rowsPerPage: val }))
              }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SuperAdminOrganization;
