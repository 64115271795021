import {
  Fi2User,
  FiArrowLeft,
  FiHeart,
  FiHome,
  IconSetting,
  LocationSvg,
  UserAddIcon,
  UserIcon,
  DateIcon,
} from "components/icons";
import LayoutForm from "pages/shared/layout/LayoutForm";
import { useCallback, useEffect, useMemo } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { cn } from "utils/common";
import { supervisorRole, userLoginNow } from "utils/roleFromLocalStorage";

const PAGE_CONFIG = [
  {
    path: "/myAccount",
    title: "My Account",
    icon: UserIcon,
    showInSidebar: true,
  },
  {
    path: "/companyDetail",
    title: "Company Details",
    icon: FiHome,
    showInSidebar: true,
  },
  {
    path: "/location",
    title: "Locations",
    icon: LocationSvg,
    showInSidebar: true,
  },
  {
    path: "/employeeStatus",
    title: "Employee Status",
    icon: Fi2User,
    showInSidebar: true,
  },
  {
    path: "/event",
    title: "Events",
    icon: FiHeart,
    showInSidebar: true,
  },
  {
    path: "/holiday",
    title: "Holidays",
    icon: DateIcon,
    showInSidebar: true,
  },
  {
    path: "/setting",
    title: "Setting",
    icon: IconSetting,
    showInSidebar: true,
  },

  { path: "/resignNotice", title: "Resign Notice", showInSidebar: false },
  {
    path: "/createLocation",
    title: "Create Location",
    backPath: "/location",
    showInSidebar: false,
  },
  {
    path: "/createEmployeeStatus",
    title: "Create Employee Status",
    backPath: "/employeeStatus",
    showInSidebar: false,
  },
  {
    path: "/createEvent",
    title: "Create Events",
    backPath: "/event",
    showInSidebar: false,
  },
  {
    path: "/addHoliday",
    title: "Add New Holidays",
    backPath: "/holiday",
    showInSidebar: false,
  },
  {
    path: "/editHoliday",
    title: "Edit Holidays",
    backPath: "/holiday",
    showInSidebar: false,
  },
  {
    path: "/editLocation",
    title: "Edit Location",
    backPath: "/location",
    showInSidebar: false,
  },
  {
    path: "/editEmployeeStatus",
    title: "Edit Employee Status",
    backPath: "/employeeStatus",
    showInSidebar: false,
  },
  {
    path: "/editEvent",
    title: "Edit Event",
    backPath: "/event",
    showInSidebar: false,
  },
];

const LayoutUserSetting = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (userLoginNow?.role?.includes("SuperAdmin")) {
      navigate("/super/organization");
    }
  }, [navigate]);

  const sortedPageConfig = useMemo(
    () => Array.from(PAGE_CONFIG).sort((a, b) => b.path.length - a.path.length),
    []
  );
  const currentPageConfig = useMemo(
    () =>
      sortedPageConfig.find((item) => location.pathname.startsWith(item.path)),
    [location.pathname, sortedPageConfig]
  );

  const allMenu = useMemo(
    () =>
      PAGE_CONFIG.filter((item) => item.showInSidebar).map(
        ({ path, title, icon: Icon, backPath }) => {
          const activePaths = [
            path,
            ...PAGE_CONFIG.filter((i) => i.backPath === path).map(
              (i) => i.path
            ),
          ];

          return {
            label: (
              <NavLink
                key={path}
                to={path}
                className={({ isActive }) =>
                  cn(
                    "flex gap-1 rounded-md p-3 w-full items-center transition-all duration-300 hover:scale-x-105 hover:cursor-pointer font-semibold text-sm",
                    (isActive ||
                      activePaths.some((p) =>
                        location.pathname.startsWith(p)
                      )) &&
                      "bg-orange-500 text-white"
                  )
                }
              >
                <Icon
                  color={
                    activePaths.some((p) => location.pathname.startsWith(p))
                      ? "white"
                      : "black"
                  }
                />
                <h1>{title}</h1>
              </NavLink>
            ),
          };
        }
      ),
    [location.pathname]
  );

  const pageTitle = useCallback(() => {
    if (!currentPageConfig) return null;

    return currentPageConfig.backPath ? (
      <div
        className="flex items-center gap-2 cursor-pointer"
        onClick={() => navigate(currentPageConfig.backPath)}
      >
        <FiArrowLeft />
        <h1 className="text-[24px]">{currentPageConfig.title}</h1>
      </div>
    ) : (
      <h1 className="text-[24px]">{currentPageConfig.title}</h1>
    );
  }, [currentPageConfig, navigate]);

  return <LayoutForm allMenu={allMenu} handleTitle={pageTitle} />;
};

export default LayoutUserSetting;
