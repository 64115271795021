import React, { useRef, useEffect, useState, useCallback } from "react";
import ReactDOM from "react-dom";
import { EllipsisVerticalIcon } from "@heroicons/react/20/solid";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cn } from "utils/common";

function FillTableMenu({
  dataUserUID,
  handleShow,
  showSetting,
  currentRole,
  onEditEmployee,
  onDeleteEmployee,
  detailEmployee,
  setShow,
  className = "",
}) {
  const menuTriggerRef = useRef();
  const menuRef = useRef();
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const initialPositionRef = useRef(null);
  const currentUser = useSelector((state) => state.loginSlice.currentUser);
  const canEditOwnData = currentUser?.uId === dataUserUID;
  const navigate = useNavigate();

  // Function to update menu position
  const updateMenuPosition = useCallback(() => {
    if (!menuTriggerRef.current || !showSetting) return;

    requestAnimationFrame(() => {
      const triggerRect = menuTriggerRef.current.getBoundingClientRect();
      const newPosition = {
        top: triggerRect.bottom + 6,
        left: triggerRect.right - 250,
      };

      // Store initial position when menu is first shown
      if (!initialPositionRef.current && showSetting) {
        initialPositionRef.current = newPosition.top;
      }

      // Check if position difference exceeds threshold
      if (
        initialPositionRef.current &&
        Math.abs(newPosition.top - initialPositionRef.current) > 30
      ) {
        setShow(false);
        initialPositionRef.current = null;
        return;
      }

      setMenuPosition(newPosition);
    });
  }, [showSetting, setShow]);

  const handleCloseMenuOnScroll = useCallback(() => {
    if (!showSetting) return;
    updateMenuPosition();
  }, [showSetting, updateMenuPosition]);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        !menuTriggerRef.current.contains(event.target)
      ) {
        setShow(false);
      }
    },
    [setShow]
  );

  // Handle menu position
  useEffect(() => {
    if (showSetting) {
      updateMenuPosition();
    }
  }, [showSetting, updateMenuPosition]);

  // Setup event listeners
  useEffect(() => {
    if (!showSetting) return;

    const captureOptions = { capture: true, passive: true };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener(
      "scroll",
      handleCloseMenuOnScroll,
      captureOptions
    );

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener(
        "scroll",
        handleCloseMenuOnScroll,
        captureOptions
      );
    };
  }, [showSetting, handleClickOutside, handleCloseMenuOnScroll]);

  if (
    !canEditOwnData &&
    !currentRole === "Admin" &&
    !currentRole === "SuperAdmin" &&
    !currentRole === "Supervisor"
  ) {
    return null;
  }

  return (
    <div className="relative" ref={menuTriggerRef}>
      <EllipsisVerticalIcon
        onClick={handleShow}
        className={cn("w-5 h-5", className)}
        aria-hidden="true"
      />
      {showSetting &&
        menuPosition.top !== 0 &&
        menuPosition.left !== 0 &&
        ReactDOM.createPortal(
          <div
            ref={menuRef}
            className={`absolute py-5 bg-white w-[250px] h-max border-[2px] border-gray-300 shadow-md rounded-md flex justify-center items-center`}
            style={{
              top: `${menuPosition.top}px`,
              left: `${menuPosition.left}px`,
              position: "absolute",
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex flex-col w-full gap-3 text-xs font-normal font-lato">
              {(currentRole === "Admin" ||
                currentRole === "SuperAdmin" ||
                currentRole === "Supervisor") && (
                <>
                  <div
                    onClick={onEditEmployee}
                    className={`px-5 hover:text-secondary hover:cursor-pointer duration-300 transition-shadow ${
                      onEditEmployee ? "block" : "hidden"
                    }`}
                  >
                    Edit
                  </div>

                  <div
                    onClick={onDeleteEmployee}
                    className={`px-5 hover:text-secondary hover:cursor-pointer duration-300 transition-shadow ${
                      onDeleteEmployee ? "block" : "hidden"
                    }`}
                  >
                    Delete
                  </div>

                  <div
                    onClick={detailEmployee}
                    className={`px-5 hover:text-secondary hover:cursor-pointer duration-300 transition-shadow ${
                      detailEmployee ? "block" : "hidden"
                    }`}
                  >
                    Detail
                  </div>
                </>
              )}

              {canEditOwnData &&
                !(
                  currentRole === "Admin" ||
                  currentRole === "SuperAdmin" ||
                  currentRole === "Supervisor"
                ) && (
                  <>
                    <div
                      onClick={() => navigate("/myAccount")}
                      className="px-5 transition-shadow duration-300 hover:text-secondary hover:cursor-pointer"
                    >
                      Edit
                    </div>
                    <div
                      onClick={detailEmployee}
                      className="px-5 transition-shadow duration-300 hover:text-secondary hover:cursor-pointer"
                    >
                      Detail
                    </div>
                  </>
                )}
            </div>
          </div>,
          document.body
        )}
    </div>
  );
}

export default FillTableMenu;
