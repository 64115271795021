import React, { useState } from "react";
import { ModalConfirmationV2 } from "@bluesilodev/timhutcomponents";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useSelector } from "react-redux";
import { alertSuccess } from "utils/alert";

import { useDeleteOneData } from "service/holidayAPI";
import FillTableMenu from "components/FillTableMenu";

function TableMenu({ data }) {
  const [show, setShow] = useState(false);
  const [uIdData, setuIdData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { currentRole } = useSelector((state) => state.loginSlice);

  const { mutate: deleteHoliday, isPending: loadingDelete } = useDeleteOneData({
    onSuccess: () => {
      queryClient.invalidateQueries("getAllHoliday");
      alertSuccess("Holiday deleted successfully");
    },
    onError: (err) => {
      console.log("err: ", err);
    },
  });

  const handleShow = () => {
    setShow(!show);
  };

  const onEditEmployee = (uId) => {
    navigate(`/editHoliday/${uId}`);
  };

  const onDeleteEmployee = (uId) => {
    setuIdData(uId);
    setShowModal(true);
  };

  const confirmModal = () => {
    // mutate(uIdData);
    deleteHoliday(uIdData);
    setShowModal(false);
    setShow(false);
  };

  const cancelModal = () => {
    setShowModal(false);
    setShow(false);
  };

  if (loadingDelete) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="relative">
        <FillTableMenu
          currentRole={currentRole}
          handleShow={handleShow}
          onDeleteEmployee={() => onDeleteEmployee(data?.uId)}
          onEditEmployee={() => onEditEmployee(data?.uId)}
          showSetting={show}
          setShow={setShow}
        />

        {showModal && (
          // <ModalConfirmation
          //   headMessage="Are you sure want to delete it?"
          //   onClose={cancelModal}
          //   onConfirm={confirmModal}
          // />
          <ModalConfirmationV2
            className={"min-w-[600px]"}
            title={"Delete Employee Status"}
            headMessage={""}
            message={
              <div className="text-left w-full text-lg font-light">
                <p>Do you want to delete this employee status type?</p>
                <p className="font-semibold">{data.employeeType}</p>
              </div>
            }
            // onClose={() => setIsShow(false)}
            onClose={cancelModal}
            // onConfirm={() => {
            //   requestPayroll({ id: original?._id });
            //   dispatch(PayrollApi.util.invalidateTags(["payroll"]));
            //   setIsShow(false);
            // }}
            onConfirm={confirmModal}
          />
        )}
      </div>
    </>
  );
}

export default TableMenu;
