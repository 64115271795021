import {
  useQuery,
  useMutation,
  useInfiniteQuery,
  useQueryClient,
} from "@tanstack/react-query";
import { customAxios } from "utils/axios";
import { buildQueryString } from "utils/common";
import { superAdminRole } from "utils/roleFromLocalStorage";

const API = `${process.env.REACT_APP_API_URL}/api/user`;

export const useSuperAdminGetAllUser = (organizationID, option) => {
  return useQuery({
    queryKey: ["getUser", organizationID],
    queryFn: async () => {
      const res = await customAxios.get(`${API}/superAdmin/${organizationID}`);
      return res.data;
    },
    enabled: !!organizationID && !!superAdminRole,
    ...option,
  });
};

export const useSuperAdminGetPendingApproval = (options) => {
  const query = {
    keyword: options.keyword || null,
    limit: options.limit || null,
    page: options.page || null,
  };

  return useQuery({
    queryKey: ["getPendingApproval", query.keyword, query.limit, query.page],
    queryFn: async () => {
      const res = await customAxios.get(
        `${API}/superAdmin/pendingApproval?${buildQueryString(query)}`
      );
      return res.data;
    },
    ...options,
  });
};

export const useSuperAdminGetPendingApprovalById = (id) => {
  return useQuery({
    queryKey: ["getPendingApprovalById", id],
    queryFn: async () => {
      const res = await customAxios.get(
        `${API}/superAdmin/pendingApproval/${id}`
      );
      return res.data;
    },
  });
};

export const useSuperAdminUpdatePendingApproval = (id) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (body) => {
      const res = await customAxios.patch(
        `${API}/superAdmin/pendingApproval/${id}`,
        body
      );
      return res.data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries("getPendingApproval");
    },
  });
};

export const useGetAllUser = (options) => {
  // DESTRUCT TO OPTIONS ALL PARAMS
  const {
    keyword,
    location,
    department,
    jobPosition,
    organizationID,
    getAll,
    rowsPerPage,
    currentPage,
    currentRole,
    ...queryOptions
  } = options;

  // Membuat fungsi untuk fetch data
  const fetchData = async () => {
    // let queryParams = "employee=true&" + (!rowsPerPage ? "limit=9999&" : "");
    let queryParams = "";

    // Mengecek apakah parameter tidak kosong, jika kosong, tidak ditambahkan ke URL
    if (rowsPerPage) queryParams += `limit=${rowsPerPage}&`;
    if (currentPage) queryParams += `page=${currentPage}&`;
    if (keyword) queryParams += `keyword=${keyword}&`;
    if (location) queryParams += `location=${location}&`;
    if (department) queryParams += `department=${department}&`;
    if (jobPosition) queryParams += `jobPosition=${jobPosition}&`;
    if (organizationID) queryParams += `organizationID=${organizationID}&`;
    if (currentRole) queryParams += `currentRole=${currentRole}&`;
    if (getAll) queryParams += `getAll=${getAll}&`;

    // Menghapus tanda '&' di akhir URL jika diperlukan
    if (queryParams.endsWith("&")) {
      queryParams = queryParams.slice(0, -1);
    }

    const url = queryParams ? `${API}?${queryParams}` : API;

    try {
      const res = await customAxios.get(url);

      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error); // Debug: Log error
      throw error;
    }
  };

  // Menggunakan queryKey default jika tidak ada parameter yang diberikan
  const queryKey = ["getUser"];
  if (rowsPerPage) queryKey.push(`limit=${rowsPerPage}`);
  if (currentPage) queryKey.push(`page=${currentPage}`);
  if (keyword) queryKey.push(`keyword=${keyword}`);
  if (location) queryKey.push(`location=${location}`);
  if (department) queryKey.push(`department=${department}`);
  if (jobPosition) queryKey.push(`jobPosition=${jobPosition}`);
  if (organizationID) queryKey.push(`organizationID=${organizationID}`);
  if (currentRole) queryKey.push(`currentRole=${currentRole}`);

  return useQuery({
    queryKey: queryKey.length > 1 ? queryKey : ["getUser"], // Gunakan queryKey yang sudah dibuat
    queryFn: fetchData,
    queryOptions,
  });
};

export const useGetInfiniteUser = (options) => {
  const {
    keyword,
    location,
    department,
    jobPosition,
    organizationID,
    getAll,
    rowsPerPage = 10, // Default limit per page
    currentRole,
    ...queryOptions
  } = options;

  // Fungsi Fetch
  const fetchUsers = async ({ pageParam = 1 }) => {
    let queryParams = `limit=${rowsPerPage}&page=${pageParam}`;

    if (keyword) queryParams += `&keyword=${keyword}`;
    if (location) queryParams += `&location=${location}`;
    if (department) queryParams += `&department=${department}`;
    if (jobPosition) queryParams += `&jobPosition=${jobPosition}`;
    if (organizationID) queryParams += `&organizationID=${organizationID}`;
    if (currentRole) queryParams += `&currentRole=${currentRole}`;
    if (getAll) queryParams += `&getAll=${getAll}`;

    const url = `${API}?${queryParams}`;

    try {
      const res = await customAxios.get(url);
      return res.data;
    } catch (error) {
      console.error("Error fetching data: ", error);
      throw error;
    }
  };

  return useInfiniteQuery({
    queryKey: [
      "getInfiniteUser",
      keyword,
      location,
      department,
      jobPosition,
      organizationID,
      currentRole,
    ],
    queryFn: fetchUsers,
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      const totalPages = Math.ceil(lastPage.totalDocs / rowsPerPage);
      return allPages.length < totalPages ? allPages.length + 1 : undefined;
    },
    // ...queryOptions,
  });
};

export const useGetAllUserReportTo = (options = {}) => {
  const { location, organizationId } = options;

  const getReport = async () => {
    const params = new URLSearchParams();

    if (location) params.append("location", location);
    if (organizationId) params.append("organizationId", organizationId);

    const queryString = params.toString();
    const url = `${API}/getReporto${queryString ? `?${queryString}` : ""}`;

    const res = await customAxios.get(url);
    return res.data;
  };

  const queryKey = ["getReportTo"];
  if (location) queryKey.push(location);
  if (organizationId) queryKey.push(organizationId);

  return useQuery({
    queryKey,
    queryFn: getReport,
    onError: (err) => {
      console.log("Something Wrong ", err);
    },
  });
};

export const useGetUserById = (uId, { onSuccess, onError }) => {
  return useQuery({
    queryKey: ["getUserById", uId],
    queryFn: async () => {
      const res = await customAxios.get(`${API}/${uId}/`);

      return res.data;
    },
    onSuccess,
    onError,
  });
};

export const useCreateUser = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: async (body) => {
      const res = await customAxios.post(`${API}`, body);

      return res.data;
    },
    onSuccess,
    onError,
  });
};

export const useDeleteUser = (id, { onSuccess, onError }) => {
  return useMutation({
    mutationFn: async () => {
      const res = await customAxios.delete(`${API}/${id}`);

      return res;
    },
    onSuccess,
    onError,
  });
};

export const useEditUser = (id, { onSuccess, onError }) => {
  return useMutation({
    mutationFn: async (body) => {
      const res = await customAxios.put(`${API}/${id}`, body);
      return res.data;
    },
    onSuccess,
    onError,
  });
};

export const useEditUserOrganization = (id, { onSuccess, onError }) => {
  return useMutation({
    mutationFn: async (body) => {
      const res = await customAxios.put(`${API}/organization/${id}`, body);
      return res.data;
    },
    onSuccess,
    onError,
  });
};

export const useDownloadExcel = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: async (body) => {
      const res = await customAxios.post(`${API}/dowloadExcel`, body, {
        responseType: "blob",
      });
      return res.data;
    },
    onSuccess,
    onError,
  });
};
