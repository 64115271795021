import { useMutation, useQuery } from "@tanstack/react-query";
import { customAxios } from "utils/axios";
import { useQueryClient } from "@tanstack/react-query";

const API = `/api/holiday`;

export const useGetAllHoliday = (options) => {
  const { rowsPerPage, currentPage, country, year } = options;
  const queryClient = useQueryClient();

  const fetchHoliday = async () => {
    let queryParams = "";

    if (rowsPerPage) queryParams += `limit=${rowsPerPage}&`;
    if (currentPage) queryParams += `pages=${currentPage}&`;
    if (country) queryParams += `country=${country}&`;
    if (year) queryParams += `year=${year}&`;

    if (queryParams.endsWith("&")) {
      queryParams = queryParams.slice(0, -1);
    }

    const url = queryParams ? `${API}?${queryParams}` : API;

    const res = await customAxios.get(url);
    return res.data;
  };

  const queryKey = ["getAllHoliday"];
  if (rowsPerPage) queryKey.push(`limit=${rowsPerPage}`);
  if (currentPage) queryKey.push(`page=${currentPage}`);
  if (country) queryKey.push(`country=${country}`);
  if (year) queryKey.push(`year=${year}`);

  return useQuery({
    queryKey: queryKey.length > 1 ? queryKey : ["getAllHoliday"],
    queryFn: fetchHoliday,
    onSuccess: () => queryClient.invalidateQueries("getAllHoliday"),
  });
};

export const useGetByIdHoliday = (id) => {
  return useQuery({
    queryKey: ["getByIdHoliday", id],
    queryFn: async () => {
      const res = await customAxios.get(`${API}/${id}`);
      return res.data;
    },
  });
};

export const useCreateHoliday = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: async (body) => {
      const res = await customAxios.post(`/api/holiday`, body);
      return res.data;
    },
    onSuccess,
    onError,
  });
};

export const useUpdateHoliday = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: async (body) => {
      const res = await customAxios.post(`${API}/updateMany`, body);
      return res.data;
    },
    onSuccess,
    onError,
  });
};

export const useDeleteOneData = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: async (uId) => {
      const res = await customAxios.delete(`${API}/${uId}`);
      return res.data;
    },
    onSuccess,
    onError,
  });
};
