import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import TableHolidays from "./tableHolidays";
import ModalSyncData from "./modalSyncData";
import AllButton from "./allButton";

function Holidays() {
  const navigate = useNavigate();
  const [showSyncModal, setShowSyncModal] = useState(false);

  const [country, setCountry] = useState("");
  const [year, setYear] = useState("");

  const handleOnchangeCountry = (e) => {
    setCountry(e.target.value);
  };

  const handleChangeYear = (e) => {
    setYear(e.target.value);
  };

  const handlleSyncModal = () => {
    setShowSyncModal(true);
  };

  const handleCloseSyncModal = () => {
    setShowSyncModal(false);
  };

  return (
    <div className="max-h-[calc(100vh-123px)]">
      <AllButton
        company={country}
        year={year}
        handleOnchangeCompany={handleOnchangeCountry}
        handleChangeYear={handleChangeYear}
        handlleSyncModal={handlleSyncModal}
      />

      {/* TABLE DATA */}
      <div className="mt-[32px]">
        <TableHolidays country={country} year={year} />
      </div>

      {/* MODAL SYNC DATA */}
      {showSyncModal && (
        <ModalSyncData handleCloseSyncModal={handleCloseSyncModal} />
      )}
    </div>
  );
}

export default Holidays;
