import { useDispatch, useSelector } from "react-redux";

import { useFilters, useLocationData } from "pages/shared/employeeList/hooks";
import TableView from "pages/shared/employeeList/TableView";
import TableMenu from "pages/superAdmin/pendingApproval/TableMenu";
import { setPagination } from "store/superAdminSlice";
import Chip from "./Chip";
import { useDebounce, usePendingApprovalData } from "./hooks";
import PendingApprovalFilters from "./PendingApprovalFilters";

const PENDING_EMPLOYEE_COLUMNS = [
  {
    id: "employee",
    accessorFn: (row) => {
      return {
        firstName: row?.firstName || "",
        lastName: !!row?.lastName ? ` ${row?.lastName}` : "",
        photo: row.photo || "",
      };
    },
    cell: (param) => {
      if (param) {
        const valueParam = param.getValue();

        return (
          <div className="flex items-center gap-2">
            <img
              className="w-[30px] h-[30px] rounded-full object-cover"
              src={valueParam?.photo[0]?.link}
              alt="firstNameImg"
            />
            <h1>{valueParam.firstName + valueParam.lastName}</h1>
          </div>
        );
      }
    },
    header: () => <span>Employee</span>,
    enableSorting: true,
    enableHiding: true,
  },
  // {
  //   id: "jobPosition",
  //   accessorFn: (row) => row?.jobPosition || "",
  //   header: () => <span>Job Position</span>,
  //   enableSorting: true,
  // },
  {
    id: "email",
    accessorFn: (row) => row?.email,
    header: () => <span>Email</span>,
    enableSorting: true,
  },
  {
    id: "dateJoin",
    accessorFn: (row) => (row?.createdAt ? row.createdAt : ""),
    header: () => <span>Date Joining</span>,
    enableSorting: true,
  },
  {
    id: "contactNumber",
    accessorFn: (row) => `${row?.countryPhoneNumber}${row?.phoneNumber}` || "",
    header: () => <span>Contact Number</span>,
    enableSorting: true,
  },
  {
    id: "userStatus",
    accessorFn: (row) => row?.status || "",
    header: () => <span>User Status</span>,
    cell: (param) => {
      if (param) {
        const value = param.getValue();
        return <Chip variant={value.toLowerCase()} />;
      }
    },
    enableSorting: false,
  },
  {
    id: "actionPendingEmployee",
    disableSorting: true,
    header: () => "",
    accessorFn: (row) => {
      return row && row;
    },
    cell: (param) => {
      if (param) {
        const data = param.getValue();

        return (
          <div className="cursor-pointer w-fit">
            <TableMenu data={data} />
          </div>
        );
      }
    },
  },
];

export default function PendingApproval() {
  const dispatch = useDispatch();

  const { statePendingApprovalPage: statePage } = useSelector(
    (state) => state.superAdminSlice
  );

  const { filters, handleFilterChange } = useFilters();
  const debouncedKeyword = useDebounce(filters.keyword, 250);
  const {
    data: pendingApprovals,
    isPending,
    isFetching,
  } = usePendingApprovalData({
    filters,
    keyword: debouncedKeyword,
    statePage,
  });

  const handlePaginationChange = (type, value) => {
    dispatch(setPagination({ ...statePage, [type]: value }));
  };

  return (
    <div>
      <div className="flex justify-between w-full max-w-4xl gap-4 mb-5">
        <PendingApprovalFilters
          filters={filters}
          onFilterChange={handleFilterChange}
        />
      </div>

      <TableView
        employees={pendingApprovals?.data || []}
        columns={PENDING_EMPLOYEE_COLUMNS}
        pagination={{
          ...statePage,
          totalData: pendingApprovals?.totalDocs || 0,
        }}
        onPaginationChange={handlePaginationChange}
      />
    </div>
  );
}
