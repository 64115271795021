import {
  ModalConfirmationV3
} from "@bluesilodev/timhutcomponents";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDeleteMyCompany } from "service/companyAPI";
import { alertError, alertSuccess } from "utils/alert";

import FillTableMenu from "components/FillTableMenu";
import { useSelector } from "react-redux";

function TableMenu({ data }) {
  const [show, setShow] = useState(false);
  const [uIdData, setuIdData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const { currentRole } = useSelector((state) => state.loginSlice);

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const handleShow = () => {
    setShow(!show);
  };

  const onEditEmployee = (uId) => {
    navigate(`/super/editCompany/${uId}`);
  };

  // DELETE USER
  const { mutate, isPending } = useDeleteMyCompany({
    onSuccess: () => {
      queryClient.invalidateQueries("getUser");
      alertSuccess("Employee deleted successfully");
    },
    onError: () => {
      alertError("FAILED DELETE DATA!");
    },
  });

  const onDeleteEmployee = (uId) => {
    setuIdData(uId);
    setShowModal(true);
  };

  const confirmModal = () => {
    mutate({ companyID: uIdData });
    setShowModal(false);
    setShow(false);
  };

  const cancelModal = () => {
    setShowModal(false);
    setShow(false);
  };

  if (isPending) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="relative">
        <FillTableMenu
          currentRole={currentRole}
          handleShow={handleShow}
          onDeleteEmployee={() => onDeleteEmployee(data?.uId)}
          onEditEmployee={() => onEditEmployee(data?.uId)}
          showSetting={show}
          setShow={setShow}
        />

        {showModal && (
          <ModalConfirmationV3
            title="Delete Organization"
            content={
              <div className="w-full text-lg font-light text-left">
                Are you sure want to delete organization "{data.companyName}"?
              </div>
            }
            size="lg"
            onCancel={cancelModal}
            onConfirm={confirmModal}
            confirmLabel="Delete"
            cancelButtonClassname="text-sm"
            confirmButtonClassname="text-sm"
          />
        )}
      </div>
    </>
  );
}

export default TableMenu;
