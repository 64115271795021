import { Accordion, DataTable } from "@bluesilodev/timhutcomponents";
import { useQueryClient } from "@tanstack/react-query";
import { useFormikContext } from "formik";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetLeaveById, useGetLeaveGlobal } from "service/leaveApi";

import EmployeeMentDetailShare from "pages/shared/formUser/EmployeementDetailShare";
import { useParams } from "react-router-dom";
import { setEditLeaveData } from "store/employeeSlice";

function EmployeeMentDetails() {
  const dispatch = useDispatch();

  const { values, handleBlur, handleChange, errors, touched, setFieldValue } =
    useFormikContext();
  const queryClient = useQueryClient();
  const { leaveEditData } = useSelector((state) => state.employeeSlice);
  const { uId } = useParams();

  const { data: dataIndividual, isSuccess: succesIndividual } = useGetLeaveById(
    uId,
    {
      onError: (err) => {
        console.log("ERR : ", err);
      },
    }
  );

  // GET DATA GLOBAL LEAVE ADMIN
  const {
    data: globalData,
    isPending: pendingGlobal,
    isSuccess: successGlobal,
  } = useGetLeaveGlobal({
    onError: (err) => {
      console.log("ERR: ", err);
    },
  });

  useEffect(() => {
    if (succesIndividual && successGlobal && dataIndividual && globalData) {
      if (
        dataIndividual.data.leaveTypeSettings &&
        dataIndividual.data.leaveTypeSettings.length > 0
      ) {
        dispatch(setEditLeaveData(dataIndividual.data.leaveTypeSettings));
      } else {
        dispatch(setEditLeaveData(globalData.data));
      }
    }
  }, [succesIndividual, successGlobal, dataIndividual, globalData]);

  // useEffect(() => {
  //   if (isSuccess && globalData) {
  //     dispatch(setLeaveData(globalData.data));
  //   }
  // }, [globalData, isSuccess]);

  if (pendingGlobal) {
    return <div>Loading...</div>;
  }

  // COLUMN
  const dataColumn = [
    {
      id: "leaveType",
      accessorFn: (row) => {
        return row.name;
      },
      header: () => <span>Leave Type</span>,
      enableSorting: true,
    },
    {
      id: "amount",
      accessorFn: (row) => row.defaultAmount,
      header: () => <span>Default Amount</span>,
      enableSorting: true,
    },
    {
      id: "gainPermont",
      accessorFn: (row) => row.gainPerMonth,
      header: () => <span>Gain Per Month</span>,
      enableSorting: true,
    },
    {
      id: "expired",
      accessorFn: (row) => row.expiredCarryOver,
      header: () => <span>Expired Carry Over</span>,
      enableSorting: true,
    },
    {
      id: "maxCarry",
      accessorFn: (row) => row?.maxCarryOver,
      header: () => <span>Max Carry Over</span>,
      enableSorting: true,
    },
  ];

  return (
    <div className="w-full ">
      <EmployeeMentDetailShare
        errors={errors}
        handleBlur={handleBlur}
        handleChange={handleChange}
        touched={touched}
        values={values}
        setFieldValue={setFieldValue}
        disabled={true}
        typeFor={"details"}
      />

      <div className="w-full">
        <Accordion
          title={"Leave Settings"}
          icons={[]}
          children={
            <div className="py-5">
              <div className="pt-5">
                <DataTable
                  columns={dataColumn}
                  data={leaveEditData}
                  pagination={true}
                  title="Employee Data"
                />
              </div>
            </div>
          }
        />
      </div>
    </div>
  );
}

export default EmployeeMentDetails;
