import React from "react";
import { InputText, InputSelect, Button } from "@bluesilodev/timhutcomponents";
import { useFormik } from "formik";
import {
  useEditUserOrganization,
  useGetAllUser,
  useGetAllUserReportTo,
} from "service/userAPI";
import { useUpdateUserInformOrganization } from "service/userInformationAPI";
import { useGetAllLocation } from "service/locationAPI";

import { useQueryClient } from "@tanstack/react-query";
import { alertSuccess, alertError } from "utils/alert";
import { adminRole } from "utils/roleFromLocalStorage";

import { cardValidationSchema } from "./CardValidation";

function ChildModalDialog({ data, setShowModal }) {
  const queryClient = useQueryClient();
  const [stringDepartmentData, setStringDepartmentData] = React.useState("");
  const [departmentData, setDepartmentData] = React.useState([]);
  const [jobPositionData, setJobPositionData] = React.useState([]);

  // GET ALL LOCATION
  const { data: location, isLoading: loadingLocation } = useGetAllLocation({
    onSuccess: () => {
      queryClient.invalidateQueries("getLocation");
    },
  });

  const { data: userReport, isLoading: loadingReportTo } =
    useGetAllUserReportTo();

  // UPDATE USER
  const { mutateAsync: updateUserOrganization, isPending: loadingUpdate } =
    useEditUserOrganization(data.uId, {
      onSuccess: () => {
        queryClient.invalidateQueries("getUser");
        alertSuccess("Employee updated successfully");
      },
      onError: (err) => {
        console.log(err);
        alertError("User failed to Updated!");
      },
    });

  // UPDATE USERINFORMATION
  const {
    mutateAsync: updateUserInformOrganization,
    isPending: loadingUpdateUserInform,
  } = useUpdateUserInformOrganization(data.userInformationID, {
    onSuccess: () => {
      queryClient.invalidateQueries("userInform");
      alertSuccess("Employee updated successfully ");
    },
    onError: (err) => {
      console.log(err);
      alertError("UserInformation failed to Updated!");
    },
  });

  const initialValues = {
    firstName: "",
    lastName: "",
    reportTo: "",
    employeementDetail: {
      locations: "",
      departments: "",
      jobPosition: "",
    },
  };

  const setToApi = {
    ...initialValues,
    firstName: data.firstName,
    lastName: data.lastName,
    reportTo: data.reportTo,
    employeementDetail: {
      locations: data.locationID,
      departments: data.departmentID,
      jobPosition: data.jobPositionID,
    },
  };

  const { handleChange, errors, handleBlur, handleSubmit, touched, values } =
    useFormik({
      initialValues: setToApi,
      onSubmit: async (val) => {
        const formUserInformation = new FormData();

        const dataUser = {
          firstName: val.firstName,
          lastName: val.lastName,
          reportTo: val.reportTo,
        };

        const dataUserInform = {
          locations: val.employeementDetail.locations,
          departments: val.employeementDetail.departments,
          jobPosition: val.employeementDetail.jobPosition,
        };

        const employeementDetail = JSON.stringify(dataUserInform);
        formUserInformation.append("employeementDetail", employeementDetail);

        try {
          const res = await updateUserInformOrganization(dataUserInform);

          if (res) {
            const update = await updateUserOrganization(dataUser);
            if (update) {
              setShowModal(false);
            }
          }
        } catch (error) {
          console.log("ERR: ", error);
        }
      },
      validationSchema: cardValidationSchema,
    });

  React.useEffect(() => {
    setStringDepartmentData(values.employeementDetail.departments);
  }, [values.employeementDetail.departments]);

  function makeUnique(arr) {
    if (!arr) return [];
    const seen = new Set();
    return arr.filter((obj) => {
      const stringified = JSON.stringify(obj);
      if (seen.has(stringified)) {
        return false;
      } else {
        seen.add(stringified);
        return true;
      }
    });
  }

  // MAPPING DEPARTMENT
  React.useEffect(() => {
    const departmentTempData = location?.data
      .filter((item) => item.uId === values.employeementDetail.locations)
      .flatMap((entry) => entry.departments)
      .map((item, index) => {
        return {
          label: item.department,
          value: item.department,
        };
      });
    const departmentFInal = [];
    departmentTempData?.forEach((item) => {
      if (!departmentFInal.some((single) => single.label === item.label)) {
        departmentFInal.push({
          label: item.label,
          value: item.value,
        });
      }
    });
    setDepartmentData(departmentFInal);
  }, [values.employeementDetail.locations, location?.data]);

  // MAPPING JOB POSITION
  React.useEffect(() => {
    const jobPositionData = location?.data
      .filter((item) => item.uId === values.employeementDetail.locations)
      .flatMap((depart) => {
        return depart.departments
          .filter((item) => item.department === stringDepartmentData)
          .flatMap((partIdx) => {
            return partIdx.jobPosition.map((item, index) => {
              return {
                label: item,
                value: item,
              };
            });
          });
      });
    const jobPositionFinal = [];
    jobPositionData?.forEach((item) => {
      if (!jobPositionFinal.some((single) => single.label === item.label)) {
        jobPositionFinal.push({
          label: item.label,
          value: item.value,
        });
      }
    });
    setJobPositionData(jobPositionFinal);
  }, [
    values.employeementDetail.locations,
    values.employeementDetail.departments,
    stringDepartmentData,
    location?.data,
  ]);

  if (
    loadingLocation ||
    loadingUpdate ||
    loadingUpdateUserInform ||
    loadingReportTo
  ) {
    return <h1>Loading...</h1>;
  }

  // MAPPING LOCATION
  const locationData = makeUnique(location?.data).map((item, _) => {
    return {
      label: item.locationName,
      value: item.uId,
    };
  });

  const subordinates =
    userReport &&
    userReport.data.length > 0 &&
    userReport.data
      .filter((item) => {
        return (
          item.reportTo === "" &&
          data.uId !== item.value &&
          data.uId !== item.reportTo &&
          item.locationuID === values.employeementDetail.locations
        );
      })
      .map((item) => {
        return {
          label: item.label,
          value: item.value,
        };
      });

  const filterUserReport =
    userReport &&
    userReport.data.length > 0 &&
    userReport.data
      .filter((item) => {
        return (
          item.locationuID === values.employeementDetail.locations &&
          // item.departMent === values.employeementDetail.departments &&
          data.uId !== item.value &&
          data.uId !== item.reportTo
        );
      })
      .map((item) => {
        return {
          label: item.label,
          value: item.value,
        };
      });

  const resultSubordinates =
    filterUserReport.length > 0 ? filterUserReport : subordinates;

  return (
    <form
      onSubmit={handleSubmit}
      autoComplete="off"
      className="w-full p-1 flex justify-center items-center flex-col"
    >
      <div className="w-[252px] h-[104px] shadow-md rounded-md flex flex-col justify-center items-center gap-[3px] mt-5">
        <div className="flex justify-between w-full">
          <div
            style={{ backgroundColor: data.color }}
            className="h-[9px] w-full rounded-tl-md"
          ></div>

          <div className="border-white shadow-sm border-[5px] w-[75px] h-[75px] rounded-full flex justify-center items-center -mt-8 ">
            <div
              style={{
                backgroundImage: `url(${data.photo})`,
              }}
              className={`bg-orange-500 w-[64px] h-[64px] rounded-full  bg-cover bg-center`}
            ></div>
          </div>

          <div
            style={{ backgroundColor: data.color }}
            className="h-[9px] w-full rounded-tr-md"
          ></div>
        </div>

        <h1 className="font-bold -mt-[5px] ">
          {data.firstName + " " + data.lastName}
        </h1>

        <div className="bg-gray-300 rounded-xl flex justify-center items-center text-center truncate max-w-[120px] px-5">
          <h1 className="font-semibold text-[11px]">{data.department}</h1>
        </div>
      </div>

      <div className="flex flex-col gap-2 w-full">
        <div className="w-full flex gap-3 mt-5">
          <InputText
            title={"First Name"}
            placeholder="Enter First Name"
            value={values.firstName}
            id="firstName"
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.firstName && touched.firstName && errors.firstName}
            required={true}
            disabled={!adminRole && true}
          />
          <InputText
            title={"Last Name"}
            placeholder="Enter Last Name"
            value={values.lastName}
            id="lastName"
            onChange={handleChange}
            onBlur={handleBlur}
            error={errors.lastName && touched.lastName && errors.lastName}
            required={true}
            disabled={!adminRole && true}
          />
        </div>

        <div className="w-full flex gap-3">
          <InputSelect
            title={"Location"}
            value={values.employeementDetail.locations}
            id="employeementDetail.locations"
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.employeementDetail &&
              errors.employeementDetail.locations &&
              touched.employeementDetail &&
              touched.employeementDetail.locations &&
              errors.employeementDetail.locations
            }
            required={true}
            options={locationData}
            disabled={!adminRole && true}
          />
          <InputSelect
            title={"Department"}
            value={values.employeementDetail.departments}
            id="employeementDetail.departments"
            onChange={(e) => {
              setStringDepartmentData(e.target.value);
              handleChange(e);
            }}
            onBlur={handleBlur}
            error={
              errors.employeementDetail &&
              errors.employeementDetail.departments &&
              touched.employeementDetail &&
              touched.employeementDetail.departments &&
              errors.employeementDetail.departments
            }
            required={true}
            options={departmentData}
            disabled={!adminRole && true}
          />
        </div>

        <div className="w-full flex gap-3">
          <InputSelect
            title={"Job position"}
            value={values.employeementDetail.jobPosition}
            z
            id="employeementDetail.jobPosition"
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              errors.employeementDetail &&
              errors.employeementDetail.jobPosition &&
              touched.employeementDetail &&
              touched.employeementDetail.jobPosition &&
              errors.employeementDetail.jobPosition
            }
            required={true}
            options={jobPositionData}
            disabled={!adminRole && true}
          />
          <InputSelect
            title={"Report To"}
            value={values.reportTo}
            id="reportTo"
            onChange={handleChange}
            options={resultSubordinates}
            disabled={!adminRole && true}
          />
        </div>
        {adminRole && (
          <Button
            type={"submit"}
            label={"Submit"}
            className={"w-full bg-orange-500 text-white my-3"}
          />
        )}
      </div>
    </form>
  );
}

export default ChildModalDialog;
