import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(customParseFormat);

export function formatCalendarDate(dateString) {
  if (!dateString) return "dd/mm/yy"; // Jika null atau undefined, return default

  const formattedDate = dayjs(dateString, "MM/DD/YYYY");

  if (formattedDate.isValid()) {
    return formattedDate.format("DD/MM/YYYY");
  } else {
    return dayjs(dateString).format("DD/MM/YYYY");
  }
}
