import React, { useState } from "react";
import { FieldArray, useFormikContext } from "formik";
import {
  Accordion,
  InputText,
  InputDate,
  InputSelect,
} from "@bluesilodev/timhutcomponents";
import { useQueryClient } from "@tanstack/react-query";

import { useGetAllCountry } from "service/commonAPI";

import { FiPlusCircle, DeleteSvg } from "components/icons";

function HolidayArrForm() {
  const { values, setFieldValue, handleBlur, handleChange, errors, touched } =
    useFormikContext();

  const queryClient = useQueryClient();
  const { data: resCountry, isPending } = useGetAllCountry({
    onSuccess: () => {
      queryClient.invalidateQueries("getCountry");
    },
  });

  if (isPending) {
    return <div>Loading...</div>;
  }

  const countryData = resCountry?.data.map((country, _) => {
    return {
      label: country.name,
      value: country.name,
    };
  });

  const dataTypeHoliday = [
    {
      label: "Public Holiday",
      value: "Public Holiday",
    },
    {
      label: "Cutber",
      value: "Cutber",
    },
  ];

  let checkVal;

  if (values?.holidays?.length > 0) {
    checkVal = Object.values(values.holidayData[0]).some((val) => {
      // alert("VALUE input : ", val);
      return typeof val === "string" && val.trim().length === 0;
    });
  }

  const dataArr = [
    {
      country: "MW",
      createdAt: "2025-03-10T07:44:00.637Z",
      endDate: "02/05/2025",
      holidayName: "Labour Day",
      holidayType: "public",
      notes: "dasfadsf",
      organizationID: "",
      startDate: "01/05/2025",
      uId: "db617a8d-e48b-463f-bc9c-7570808d5f06",
      updatedAt: "2025-03-11T04:09:06.216Z",
      year: "2025",
      _id: "67ce984029d4e1d9b82f3b1a",
    },
    {
      country: "MW",
      createdAt: "2025-03-10T08:00:00.123Z",
      endDate: "26/12/2025",
      holidayName: "Boxing Day",
      holidayType: "public",
      notes: "Libur nasional setelah Natal",
      organizationID: "",
      startDate: "26/12/2025",
      uId: "f47ac10b-58cc-4372-a567-0e02b2c3d479",
      updatedAt: "2025-03-11T04:20:15.987Z",
      year: "2025",
      _id: "67ce984029d4e1d9b82f3b1b",
    },
  ];

  return (
    <div>
      <FieldArray name="holidayData">
        {({ remove, push }) => {
          return (
            <div className="flex flex-col gap-10 w-[80%]">
              {values.holidayData.map((item, index) => {
                return (
                  <Accordion
                    key={index}
                    icons={[
                      {
                        icon: (
                          <>
                            {index > 0 && (
                              <DeleteSvg
                                onClick={() => {
                                  remove(index);
                                }}
                              />
                            )}
                          </>
                        ),
                      },
                    ]}
                    title={"Holiday Information"}
                    children={
                      <div className="flex flex-col gap-3">
                        <div className="flex gap-3">
                          <InputText
                            id={`holidayData.${index}.holidayName`}
                            title={"Name Of Holiday"}
                            required={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={item.holidayName}
                            error={
                              errors.holidayData &&
                              errors.holidayData[index] &&
                              errors.holidayData[index].holidayName &&
                              touched.holidayData &&
                              touched.holidayData[index] &&
                              touched.holidayData[index].holidayName &&
                              errors.holidayData[index].holidayName
                            }
                          />

                          <InputDate
                            name={`holidayData.${index}.startDate`}
                            label={"Holiday Start Date"}
                            required={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={item.startDate}
                            setFieldValue={setFieldValue}
                            errors={
                              errors.holidayData &&
                              errors.holidayData[index] &&
                              errors.holidayData[index].startDate &&
                              touched.holidayData &&
                              touched.holidayData[index] &&
                              touched.holidayData[index].startDate &&
                              errors.holidayData[index].startDate
                            }
                          />
                        </div>

                        <div className="flex gap-3">
                          <InputSelect
                            id={`holidayData.${index}.country`}
                            title={"Country"}
                            options={countryData}
                            required={true}
                            onChange={(e) => {
                              setFieldValue(
                                `holidayData.${index}.country`,
                                e.target.value
                              );

                              // setGetValCodeCountry(e.target.value);
                            }}
                            onBlur={handleBlur}
                            value={item.country}
                            error={
                              errors.holidayData &&
                              errors.holidayData[index] &&
                              errors.holidayData[index].country &&
                              touched.holidayData &&
                              touched.holidayData[index] &&
                              touched.holidayData[index].country &&
                              errors.holidayData[index].country
                            }
                          />
                          <InputDate
                            name={`holidayData.${index}.endDate`}
                            label={"Holiday End Date"}
                            required={true}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            value={item.endDate}
                            setFieldValue={setFieldValue}
                            errors={
                              errors.holidayData &&
                              errors.holidayData[index] &&
                              errors.holidayData[index].endDate &&
                              touched.holidayData &&
                              touched.holidayData[index] &&
                              touched.holidayData[index].endDate &&
                              errors.holidayData[index].endDate
                            }
                          />
                        </div>

                        <InputSelect
                          id={`holidayData.${index}.holidayType`}
                          title={"Holiday Type"}
                          options={dataTypeHoliday}
                          required={true}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          classname={"max-w-[49.5%]"}
                          value={item.holidayType}
                          error={
                            errors.holidayData &&
                            errors.holidayData[index] &&
                            errors.holidayData[index].holidayType &&
                            touched.holidayData &&
                            touched.holidayData[index] &&
                            touched.holidayData[index].holidayType &&
                            errors.holidayData[index].holidayType
                          }
                        />

                        <textarea
                          className="w-full resize-none h-[150px] border-[1px] border-black outline-none p-3 rounded-md"
                          placeholder="Notes"
                          value={item.notes}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          id={`holidayData.${index}.notes`}
                        ></textarea>
                      </div>
                    }
                  />
                );
              })}

              <button
                type="button"
                disabled={checkVal}
                className={`${
                  checkVal ? "bg-gray-200 " : "bg-orange-500"
                }  h-[58px] rounded-md flex justify-center items-center mb-5`}
                onClick={() => [
                  push({
                    holidayName: "",
                    startDate: "",
                    endDate: "",
                    country: "",
                    holidayType: "",
                    notes: "",
                  }),
                ]}
              >
                <div
                  className={`flex gap-3 ${
                    checkVal ? "text-black" : "text-white"
                  } `}
                >
                  <FiPlusCircle />
                  <h1 className="">Add More Holiday Date</h1>
                </div>
              </button>
            </div>
          );
        }}
      </FieldArray>
    </div>
  );
}

export default HolidayArrForm;
