import { InputSelect, SearchBox } from "@bluesilodev/timhutcomponents";
import ViewSwitch from "pages/shared/employeeList/ViewSwitch";
import { memo, useCallback, useEffect, useRef, useState } from "react";

const EmployeePageFilters = ({
  filters,
  locationData,
  departmentData,
  jobPositionData,
  onFilterChange,
  isGridMode,
  setIsGridMode,
}) => {
  const [localKeyword, setLocalKeyword] = useState(filters.keyword);
  const timeoutRef = useRef();

  // Sync local state with external filter changes
  useEffect(() => {
    setLocalKeyword(filters.keyword);
  }, [filters.keyword]);

  const handleSearchDebounce = useCallback(
    (value) => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => {
        onFilterChange("keyword", value);
      }, 500);
    },
    [onFilterChange]
  );

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setLocalKeyword(value);
    handleSearchDebounce(value);
  };

  return (
    <div className="flex w-full gap-4">
      <InputSelect
        value={filters.location}
        onChange={(e) => onFilterChange("location", e.target.value)}
        title="Location"
        id="location"
        options={locationData}
        placeholder="All Locations"
        classname="w-full"
      />

      <InputSelect
        onChange={(e) => onFilterChange("department", e.target.value)}
        title={"Department"}
        options={departmentData}
        placeholder="All Departments"
        classname={"w-full"}
      />

      <InputSelect
        id="jobPosition"
        onChange={(e) => onFilterChange("jobPosition", e.target.value)}
        title={"Job Position"}
        options={jobPositionData}
        placeholder="All Job Positions"
        classname={"w-full"}
      />

      <div className="flex gap-4">
        <ViewSwitch {...{ isGridMode, setIsGridMode }} />
      </div>

      <SearchBox
        value={localKeyword}
        onChange={handleSearchChange}
        placeholder="Search by name"
        className="w-full"
      />
    </div>
  );
};

export default memo(EmployeePageFilters);
