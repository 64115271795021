import { useQuery, useMutation } from "@tanstack/react-query";
import axios from "axios";
import { LeaveAxios } from "utils/axios";
import { getLocalStorage } from "utils/common";

const API = `${process.env.REACT_APP_LEAVE_API_URL}`;

const { config } = getLocalStorage();

// GET ALL API GLOBAL
export const useGetLeaveGlobal = ({ onSuccess, onError }) => {
  return useQuery({
    queryKey: ["globalLeave"], // Gunakan queryKey yang sudah dibuat
    queryFn: async () => {
      const res = await axios.get(`${API}/api/leave-type/get`, config);

      return res.data;
    },
    onSuccess,
    onError,
  });
};

// GET SINGLE USER
export const useGetLeaveById = (
  uId,
  { onSuccess = () => {}, onError = () => {} }
) => {
  return useQuery({
    queryKey: ["getLeaveDataID", uId],
    queryFn: async () => {
      const res = await axios.get(
        `${API}/api/leave-user/get-user-type/${uId}`,
        config
      );

      return res.data;
    },
    enabled: !!uId,
    onSuccess,
    onError,
  });
};

export const useAddLeaveTypeByUser = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: async (body) => {
      const response = await LeaveAxios.post(
        `${API}/api/leave-type/createByUser`,
        {
          name: body.name,
          defaultAmount: body.defaultAmount,
          gainPerMonth: body.gainPerMonth,
          expiredCarryOver: body.expiredCarryOver,
          maxCarryOver: body.maxCarryOver,
          periodOfEmployments: body.periodOfEmployments,
          isFullLeaveAmount: body.isFullLeaveAmount,
          assignTo: body.assignTo,
        }
      );

      return response.data;
    },
    onSuccess,
    onError,
  });
};

export const useUpdateLeaveTypeByUser = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: async (body) => {
      const response = await LeaveAxios.put(
        `${API}/api/leave-type/updateByUser/${body.name}`,
        body
      );
      return response.data;
    },
    onSuccess,
    onError,
  });
};

export const useAddLeaveTypeByAdmin = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: async (body) => {
      const response = await LeaveAxios.post(`${API}/api/leave-type/create`, {
        name: body.name,
        defaultAmount: body.defaultAmount,
        gainPerMonth: body.gainPerMonth,
        expiredCarryOver: body.expiredCarryOver,
        maxCarryOver: body.maxCarryOver,
        periodOfEmployments: body.periodOfEmployments,
        isFullLeaveAmount: body.isFullLeaveAmount,
        assignTo: body.assignTo,
      });

      return response.data;
    },
    onSuccess,
    onError,
  });
};

export const useUpdateLeaveTypeByAdmin = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: async (body) => {
      const response = await LeaveAxios.put("/api/leave-type", {
        name: body.name,
        defaultAmount: body.defaultAmount,
        gainPerMonth: body.gainPerMonth,
        expiredCarryOver: body.expiredCarryOver,
        maxCarryOver: body.maxCarryOver,
        periodOfEmployments: body.periodOfEmployments,
        isFullLeaveAmount: body.isFullLeaveAmount,
        assignTo: body.assignTo,
      });
      
      return response.data;
    },
    onSuccess,
    onError,
  });
};

export const useDeleteLeaveTypeByAdmin = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: async (body) => {
      const response = await LeaveAxios.delete(
        `${API}/api/leave-type/${body.name}`
      );
      return response.data;
    },
    onSuccess,
    onError,
  });
};

export const useAddLeaveTypeUserByAdmin = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: async (body) => {
      const response = await LeaveAxios.post(
        `${API}/api/leave-type/byAdmin/create`,
        {
          userID: body.userID, // Include userID in request
          name: body.name,
          defaultAmount: body.defaultAmount,
          gainPerMonth: body.gainPerMonth,
          expiredCarryOver: body.expiredCarryOver,
          maxCarryOver: body.maxCarryOver,
          periodOfEmployments: body.periodOfEmployments,
          isFullLeaveAmount: body.isFullLeaveAmount,
          assignTo: body.assignTo,
        }
      );

      return response.data;
    },
    onSuccess,
    onError,
  });
};

export const useUpdateLeaveTypeUserByAdmin = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: async (body) => {
      const response = await LeaveAxios.put(
        `${API}/api/leave-type/byAdmin/${body.name}`,
        {
          userID: body.userID, // Include userID in request
          leaveTypeID: body.leaveTypeID,
          name: body.name,
          defaultAmount: body.defaultAmount,
          gainPerMonth: body.gainPerMonth,
          expiredCarryOver: body.expiredCarryOver,
          maxCarryOver: body.maxCarryOver,
          periodOfEmployments: body.periodOfEmployments,
          isFullLeaveAmount: body.isFullLeaveAmount,
          assignTo: body.assignTo,
        }
      );

      return response.data;
    },
    onSuccess,
    onError,
  });
};

export const useDeleteLeaveTypeUserByAdmin = ({ onSuccess, onError }) => {
  return useMutation({
    mutationFn: async ({ name, userID, leaveTypeID }) => {
      const response = await LeaveAxios.delete(
        `${API}/api/leave-type/byAdmin/${name}`,
        {
          data: { userID, name, leaveTypeID }, // Pass userID in the request body
        }
      );
      return response.data;
    },
    onSuccess,
    onError,
  });
};
