import { DataTable, Pagination } from "@bluesilodev/timhutcomponents";
import TableMenu from "../../superAdmin/pendingApproval/TableMenu";
import DateDisplay from "./DateDisplay";
import { memo, useMemo } from "react";

const TableView = ({
  employees,
  columns,
  pagination,
  onPaginationChange,
  className,
}) => {
  const processedColumns = useMemo(
    () =>
      columns.map((col) => {
        if (col.id === "action") {
          return {
            ...col,
            cell: (param) => (
              <div className="cursor-pointer w-fit">
                <TableMenu data={param.getValue()} />
              </div>
            ),
          };
        }
        if (col.id === "dateJoin") {
          return {
            ...col,
            cell: (param) => <DateDisplay dateStr={param.getValue()} />,
          };
        }
        return col;
      }),
    [columns]
  );

  return (
    <>
      <div className={className}>
        <DataTable
          title="Employee Data"
          columns={processedColumns}
          data={employees}
          pagination={false}
          className="max-h-[calc(100dvh-310px)]"
        />

        <div className="py-2 mt-4" />

        <Pagination
          totalData={pagination.totalData}
          rowsPerPage={pagination.rowsPerPage}
          currentPage={pagination.currentPage}
          onChangeCurrentPage={(val) =>
            onPaginationChange("currentPage", val + 1)
          }
          onChangeRowsPerPage={(val) => onPaginationChange("rowsPerPage", val)}
        />
      </div>
    </>
  );
};

export default memo(TableView);
