import React from "react";
import {
  Accordion,
  InputText,
  InputPassword,
} from "@bluesilodev/timhutcomponents";

function UserCredential({
  handleChange,
  handleBlur,
  values,
  errors,
  touched,
  disabled,
  disabledPassword,
}) {
  return (
    <div className="w-full rounded-md ">
      <Accordion
        title={"User Credential"}
        icons={[]}
        children={
          <div className="flex flex-col w-full gap-3">
            <div className="flex w-full gap-3 pt-3">
              <InputText
                type={"email"}
                required={true}
                title={"Email Address"}
                className={"flex items-center gap-2 w-full"}
                placeholder="Enter Email Address"
                label={null}
                onChange={handleChange}
                disabled={disabled ? disabled : false}
                onBlur={handleBlur}
                value={values.email}
                id={"email"}
                error={
                  <div>{errors.email && touched.email && errors.email}</div>
                }
              />
              <InputText
                title={"Username"}
                placeholder="Enter Username"
                required={true}
                className={"flex items-center gap-2 w-full"}
                label={null}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.userName}
                disabled={disabled ? disabled : false}
                id={"userName"}
                error={
                  <div>
                    {errors.userName && touched.userName && errors.userName}
                  </div>
                }
              />
            </div>

            <div className="flex gap-3">
              <InputPassword
                title={"Password"}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                // disabled={disabled ? disabled : false}
                disabled={disabledPassword}
                required={!disabledPassword}
                // disable={false}
                placeholder={"************"}
                id={"password"}
                error={
                  <div>
                    {errors.password && touched.password && errors.password}
                  </div>
                }
              />

              <InputPassword
                title={"Confirm Password"}
                // disable={true}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.confirmPassword}
                // disabled={disabled ? disabled : false}
                disabled={disabledPassword}
                required={!disabledPassword}
                placeholder={"************"}
                id={"confirmPassword"}
                error={
                  <div>
                    {errors.confirmPassword &&
                      touched.confirmPassword &&
                      errors.confirmPassword}
                  </div>
                }
              />
            </div>
          </div>
        }
      />
    </div>
  );
}

export default UserCredential;
