import React, { useState, useEffect } from "react";
import { DataTable, Pagination } from "@bluesilodev/timhutcomponents";
import { useSelector } from "react-redux";

import TableMenu from "./tableMenu";
import { useGetAllHoliday } from "service/holidayAPI";
import { formattedDate, getDayOfWeek } from "utils/common";

function TableHolidays({ country, year }) {
  const [paginate, setPaginate] = useState({
    totalData: 0,
    rowsPerPage: 10,
    currentPage: 1,
  });

  const { currentRole } = useSelector((state) => state.loginSlice);

  const { data: dataHoliday, isLoading: loadingHoliday } = useGetAllHoliday({
    rowsPerPage: paginate.rowsPerPage,
    currentPage: paginate.currentPage,
    country: country,
    year: year,
  });

  useEffect(() => {
    if (!loadingHoliday) {
      setPaginate((oldData) => ({
        ...oldData,
        totalData: dataHoliday.totalDoc,
      }));
    }
  }, [dataHoliday, paginate.currentPage, paginate.rowsPerPage]);

  if (loadingHoliday) return <div>Loading...</div>;

  const dataColumn = [
    {
      id: "date",
      accessorFn: (row) => formattedDate(row.startDate),
      header: () => <div>Date</div>,
      enableSorting: true,
      sortFunction: undefined,
    },
    {
      id: "year",
      accessorFn: (row) => {
        return row?.year;
      },
      header: () => <div>Year</div>,
      enableSorting: true,
      sortFunction: undefined,
    },
    {
      id: "dayOfTheWeek",
      accessorFn: (row) => getDayOfWeek(row.startDate),

      header: () => <div>Day Of The Week</div>,
      enableSorting: true,
      sortFunction: undefined,
    },
    {
      id: "country",
      accessorFn: (row) => row.country,
      //   cell: (param) => {
      //     const val = param.getValue();
      //     // return <DateDisplay dateStr={val} />;
      //   },
      header: () => <div>Country</div>,
      enableSorting: true,
      sortFunction: undefined,
    },

    {
      id: "holidayName",
      accessorFn: (row) => row.holidayName,
      header: () => <div>Holiday Name</div>,
      enableSorting: true,
      sortFunction: undefined,
    },
    {
      id: "holidayType",
      accessorFn: (row) => row.holidayType,
      //   cell: (param) => {
      //     const val = param.getValue();
      //     return <div>{val === true ? "yes" : "no"}</div>;
      //   },
      header: () => <div>Holiday Type</div>,
      enableSorting: true,
      sortFunction: undefined,
    },
    {
      id: "notes",
      accessorFn: (row) => row.notes,
      header: () => <div>Notes</div>,
      enableSorting: true,
      sortFunction: undefined,
    },
  ];

  if (currentRole === "Admin" || currentRole === "Supervisor" ) {
    dataColumn.push({
      id: "action",
      disableSorting: true,
      header: () => "",
      accessorFn: (row) => {
        return row;
      },
      cell: (param) => {
        const data = param.getValue();

        return (
          <div className="cursor-pointer w-fit">
            <TableMenu data={data} />
          </div>
        );
      },
    });
  }

  // DUMY DATA
  const data = [
    {
      date: "19 jan 2020",
      year: "2020",
      dayOfTheWeek: "monday",
      country: "indonesia",
      holidayName: "new years day",
      holidayType: "public holiday",
      notes: "start of the new year",
    },
  ];

  return (
    <div>
      <DataTable
        columns={dataColumn}
        data={!dataHoliday?.data ? <h1>Data Not Found!</h1> : dataHoliday?.data}
        title="Example DataTable"
        totalRows={100}
        className="max-h-[calc(100dvh-360px)]"
        pagination={false}
      />

      <Pagination
        currentPage={paginate.currentPage}
        totalData={paginate.totalData}
        rowsPerPage={paginate.rowsPerPage}
        onChangeCurrentPage={(val) =>
          setPaginate((oldData) => ({ ...oldData, currentPage: val + 1 }))
        }
        onChangeRowsPerPage={(val) =>
          setPaginate((oldData) => ({ ...oldData, rowsPerPage: val }))
        }
      />
    </div>
  );
}

export default TableHolidays;
