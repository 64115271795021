import React, { useState } from "react";
import { Calendar } from "react-date-range";
import PropTypes from "prop-types";
import dayjs from "dayjs";

const InputDate = ({
  required,
  label,
  name,
  disabled,
  value,
  errors,
  setFieldValue,
  positionCalendar,
  ...props
}) => {
  const ref = React.useRef(null);
  const [showCalendar, setShowCalendar] = useState(false);

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setShowCalendar(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChangeCalendar = (date) => {
    setFieldValue(name, dayjs(date).format("DD/MM/YYYY"));
    setShowCalendar(false);
  };

  const handleCLick = () => {
    setShowCalendar(!showCalendar);
  };

  const getInitialDate = () => {
    if (value instanceof Date) return value;
    const parseDate = dayjs(value, ["DD/MM/YYYY", "DD/MM/YY"]);

    if (parseDate.isValid()) return parseDate.toDate();

    return new Date();
  };

  return (
    <div ref={ref} className={`w-full relative select-none`}>
      <div className="w-full relative h-[50px]">
        <div
          className={`w-full h-[60px] ${
            !disabled && "border-[1px]"
          } border-black p-3 relative rounded-md ${disabled && "bg-[#F2F4F5]"}`}
        >
          <div
            className={`absolute top-[6px] max-w-[350px] text-[14px] truncate text-black ${
              disabled ? "z-[41]" : "z-[40]"
            }`}
          >
            {label} <span className="text-black">{required ? "*" : ""}</span>{" "}
          </div>

          <input
            type="text"
            id={name}
            value={
              value === ""
                ? "dd/mm/yy"
                : value === "-" // Jika value adalah "-", tampilkan apa adanya
                ? value
                : value
            }
            onClick={handleCLick}
            readOnly
            className={` w-full h-full outline-none rounded-md cursor-pointer absolute top-0 left-0 pt-5 pl-3 text-[14px] bg-transparent z-40 ${
              disabled && "bg-[#F2F4F5]"
            } select-none`}
            {...props}
          />
        </div>

        <div
          className="absolute right-[15px] top-[35%] cursor-pointer z-[50]"
          onClick={handleCLick}
        >
          <CalendarSvg />
        </div>
      </div>

      {errors && !showCalendar && (
        <div className={` text-red-500 pt-[7px]`}>{errors}</div>
      )}

      <div className={`absolute top-[45px] ${positionCalendar}  z-50 `}>
        {showCalendar && !disabled && (
          <Calendar
            date={getInitialDate()}
            onChange={(date) => handleChangeCalendar(date)}
            className="rounded-md shadow-md "
          />
        )}
      </div>
    </div>
  );
};

InputDate.propTypes = {
  required: PropTypes.bool,
  label: PropTypes.string,
  value: PropTypes.string,
  errors: PropTypes.any,
  setFieldValue: PropTypes.func,
  disabled: PropTypes.bool,
  positionCalendar: PropTypes.string,
};

InputDate.defaultProps = {
  required: false,
  label: "",
  name: "",
  value: "",
  errors: null,
  setFieldValue: () => {},
  onChange: () => {},
  disabled: false,
  // YOU CAN ADDING YOUR POSITION
  positionCalendar: "",
};

export default InputDate;

function CalendarSvg() {
  return (
    <svg
      width="20"
      height="22"
      viewBox="0 0 20 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.09277 8.40445H18.9167"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.442 12.3088H14.4512"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0045 12.3088H10.0137"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.55769 12.3088H5.56695"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.442 16.1955H14.4512"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0045 16.1955H10.0137"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.55769 16.1955H5.56695"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.0438 1V4.29078"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.96564 1V4.29078"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.2383 2.58008H5.77096C2.83427 2.58008 1 4.21601 1 7.2231V16.2727C1 19.3271 2.83427 21.0009 5.77096 21.0009H14.229C17.175 21.0009 19 19.3555 19 16.3484V7.2231C19.0092 4.21601 17.1842 2.58008 14.2383 2.58008Z"
        stroke="#1A1A2E"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
