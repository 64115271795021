import { Skeleton } from "@bluesilodev/timhutcomponents";
import CardGrid from "pages/shared/employeeList/CardGrid";
import { memo, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useGetInfiniteUser } from "service/userAPI";
import { cn } from "utils/common";

const GridView = ({ className, filters, cardGridWrapperClassName }) => {
  const sentinelRef = useRef();
  const { currentRole } = useSelector((state) => state.loginSlice);

  const {
    data: employees,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
    isPending,
  } = useGetInfiniteUser({
    ...filters,
    currentRole,
    rowsPerPage: 10,
  });

  useEffect(() => {
    if (!sentinelRef.current || !hasNextPage) return;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting && !isFetchingNextPage && hasNextPage)
          fetchNextPage();
      },
      {
        threshold: 0.8,
      }
    );

    observer.observe(sentinelRef.current);

    return () => {
      observer.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasNextPage, isFetchingNextPage]);

  if (
    employees?.pages?.[0]?.data?.length === 0 &&
    !isLoading &&
    !isPending &&
    !isFetchingNextPage
  ) {
    return (
      <div className="w-full text-center text-gray-500">Data Not Found!</div>
    );
  }

  return (
    <div
      className={cn(
        "will-change-transform pb-5 gap-5 grid grid-cols-[repeat(auto-fill,minmax(290px,1fr))] overflow-auto max-h-[calc(100vh-203px)]",
        className
      )}
      style={{
        // this style will enable gpu rendering
        backfaceVisibility: "hidden",
        transform: "translate3d(0, 0, 0)",
      }}
    >
      {employees?.pages?.length &&
        employees?.pages?.map((page) => {
          return page?.data?.map((item) => {
            return (
              <div
                key={item.uId}
                className={cn("min-h-[310px] flex-1", cardGridWrapperClassName)}
              >
                <CardGrid item={item} />
              </div>
            );
          });
        })}

      {(isPending || isLoading || isFetchingNextPage) &&
        Array.from({ length: 3 }).map(() => (
          <div className="min-h-[310px] flex-1">
            <Skeleton className="w-full h-full rounded-[16px]" />
          </div>
        ))}

      {hasNextPage && (
        <div ref={sentinelRef} className="min-h-[310px] flex-1" />
      )}
    </div>
  );
};

export default memo(GridView);
